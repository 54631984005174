import {makeStyles} from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    welcomeContainer: {
        height: "100%",
        backgroundColor: "#99bfc2",
        [theme.breakpoints.down('lg')]: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
    },
    welcomeItem: {
        textAlign: "left",
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
        color: "white",
        [theme.breakpoints.down('lg')]: {
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        },
    },
    signInLink: {
        color: "white",
    },
    imageGridContainer: {
        backgroundColor: "white",
    },
    imageGrid: {
        padding: theme.spacing(2),
        backgroundColor: "white",
    },
    createPageFix: {
        minHeight: "50vh",
    },
    deleteCard: {
        position: 'relative',
    },
    deleteOverlay: {
        position: 'absolute',
        bottom: 10,
        right: 10,
        width: '45px',
        height: '45px',
    },
    deleteBackdrop: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top:0,
        left:0,
        background: 'rgba(0,0,0,0.6)',
    },

}));

export default useStyles