import React from "react"
import PhotobookProductInner from "./PhotoBookProductInner";
import {useProducts} from "../../API";
import { usePhotobook } from "../../models";
import _ from "lodash";

const PhotobookProduct = ({user, currency}) => {

    //todo
    // const [photobook, photobooksLoading, photobookError] = usePhotobook(user.uid, user.uid);
    // const [productResponse, error] = useProducts("photobooks", currency);

    const [photobook] = usePhotobook(user.uid, user.uid);
    const [productResponse] = useProducts("photobooks", currency);

    const products = _.get(productResponse, "products")
    const frontPage = _.get(photobook, "frontPage");
    if (photobook && products && frontPage) {
        return (
            <PhotobookProductInner photobook={photobook} product={products[0]} frontPage={frontPage} user={user} currency={currency}></PhotobookProductInner>
        )
    }
    return (null)
}

export default PhotobookProduct