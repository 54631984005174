/* React */
/*jshint esversion: 6 */
import React from "react";
import Grid from "@mui/material/Grid";
import {ProductCard} from "./ProductCard";




const ProductsGrid = ({products, jigpaw, user, currency}) => {

    if (products && jigpaw) {

        return (
            <Grid container spacing={3}>
                {products.map(doc => (
                        <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                            <ProductCard product={doc} jigpaw={jigpaw} user={user} currency={currency}></ProductCard>
                        </Grid>
                    )
                )}
            </Grid>
        )
    } else {
        return (
            null
        )
    }
}

export default ProductsGrid
