//REACT
import React from "react"

//FIREBASE
//MATERIALS
//INTERNAL IMPORTS

//EXTERNAL IMPORTS
import _ from "lodash";

//COMPONENTS
import JigpawCardPlaceHolder from "./JigPawCardPlaceholder";
import JigpawCard from "./JigPawCard";
import { Square } from "../../global/components/Square";

//HOOKS, UTILS, DATA

const JigpawType = (props) => {
    const {jigpaw} = props;
    const oid = _.get(jigpaw, "oid")
    const notOwner = (oid && oid !== _.get(jigpaw, "uid"));
    const uploaded = _.get(jigpaw, "stored.image");
    if (notOwner || uploaded) {
        return (
            <JigpawCard key={`${jigpaw.oid}${jigpaw.uuid}`} {...props}></JigpawCard>
        )
    }
    return (
        <Square>
            <JigpawCardPlaceHolder key={`${jigpaw.oid}${jigpaw.uuid}`} jigpaw={jigpaw}></JigpawCardPlaceHolder>
        </Square>
    )
}

export default JigpawType