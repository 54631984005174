import React from 'react';
import {Responsive, WidthProvider} from 'react-grid-layout';
import _ from 'lodash';
import axios from 'axios';
import ReactGA from "react-ga";
import {createUrl} from "./API";
import Button from '@mui/material/Button'; 
import BalanceText from "react-balance-text";
import { Container, Grid } from '@mui/material'
import Helmet from "react-helmet";
import CenterGrid from './CenterGrid';
import SliderSection from './ShippingFooter/SliderSection';


const ResponsiveGridLayout = WidthProvider(Responsive);

const buttonStyles = {
    padding: '20px 60px',
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0.05em"
};

const onGotoAppStore = (e) => {
    ReactGA.event({
        category: "User",
        action: 'Clicked through to appstore',
        label: "https://apps.apple.com/gb/app/jigpaws/id1541336283"
    })
};

class MyResponsiveGrid extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rowHeight: 50,
            data: null,
            layouts: {},
        }

        this.onWidthChange = this.onWidthChange.bind(this);

    }

    componentDidMount() {
        this.renderMyData();
    }

    renderMyData() {
        let bucket_size = 45;
        axios.get(
            createUrl("api/jigpaws/"),
            {
                params: {
                    limit: 45
                },
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        ).then((response) => {
            // handle success
            // console.log(response);
            this.setState({data: response.data})
            let largeLayout = this._largeLayout(bucket_size + 10, 10)
            let smallLayout = this._largeLayout(bucket_size + 10, 6)
            let xsLayout = this._smallLayout(bucket_size)

            const layouts = {
                'lg': largeLayout,
                'md': largeLayout,
                'sm': smallLayout,
                'xs': xsLayout,
                'xxs': xsLayout,
            }
            this.setState({'layouts': layouts})
        })
    }

    onWidthChange = function (width, margin, cols, padding) {
        var marginWidths = (cols - 1) * margin[0]
        var availableWidth = width - marginWidths
        var height = availableWidth / cols

        // console.log("width", width)
        // console.log("margin", margin);
        // console.log("availableWidth", availableWidth)
        // console.log("marginWidths", marginWidths)

        // console.log("cols", cols)
        // console.log("height", height);
        // console.log("padding", padding);
        this.setState({rowHeight: height})
    };

    _largeLayout = function (bucket_size, columns) {
        let largeLayout = Array(bucket_size)
        let x = 0
        let y = 0
        let row = 0
        for (let i = 0; i < bucket_size; i++) {
            let key = i + 1
            let big = i % 3 === 0
            let oddRow = row % 2 === 1
            let o = {i: key.toString(), x: x, y: y, w: 1, h: 1, static: true}
            x += 1;
            if (big) {
                o = Object.assign(o, {w: 2, h: 2});
                x += 1;
                if (oddRow) {
                    o = _.assign(o, {y: y - 1});
                }
            }
            if (x > columns - 1) {
                if (oddRow) {
                    y += 1
                } else {
                    y += 2
                }
                x = 0
                row += 1;
            }
            largeLayout[i] = o
        }
        return largeLayout
    }

    _smallLayout = function (bucket_size) {
        let smallLayout = Array(bucket_size)
        let x = 0
        let y = 0
        for (let i = 0; i < bucket_size; i++) {
            let key = i + 1
            let big = i % 3 === 0
            let o = {i: key.toString(), x: x, y: y, w: 1, h: 1, static: true}
            if (big) {
                o = Object.assign(o, {w: 2, h: 2});
                x = 0;
                y += 2;
            } else {
                if (x === 0) {
                    x += 1
                } else {
                    y += 1
                    x = 0
                }
            }
            smallLayout[i] = o
        }
        return smallLayout
    }

    generateDOM() {

        if (!this.state.data) {
            return (
                <div key="1"></div>
            )
        }

        let offset = 1;
        let ret = _.map(this.state.data.jigpaws, (url, i) => {
            let key = i + offset;
            return (
                <div key={key}>
                    <img src={url} className={"jigpaw"}/>
                </div>
            );
        });
        return ret
    }


    render() {

        return (
            <>
                <Helmet>
                    <title>Feature in our pet photo gallery</title>
                    <meta name="description"
                          content="Create a personalised photo gift to feature in our 'Jigpaws of the Day' gallery. Share a little piece of the family."/>
                    {/*<link rel="canonical" href="https://www.jigpaws.com/gallery-pet-photos"/>*/}
                    <meta property="og:url"
                          content="https://www.jigpaws.com/gallery-pet-photos"/>
                    <meta property="og:type" content="article"/>
                    <meta property="og:title" content="Jigpaws – Photo of the day"/>
                    <meta property="og:description"
                          content="Create a personalised photo gift to feature in our 'Jigpaws of the Day' gallery."/>
                    <meta property="og:image" content="https://www.jigpaws.com/images/jigpaws-social-4.jpg"/>
                </Helmet>
                <Container className={"productsHome bgGray"} align="center" disableGutters={true}
                           maxWidth={false}>
                    <div className="spacer"/>
                    <div className="headerContainer">

                        <div><BalanceText><h1>Photo of the day</h1></BalanceText></div>
                        <div className="body leader"><BalanceText>Create a personalised photo gift to feature in our 'Jigpaws of the Day' gallery. Share a little piece of the family.
</BalanceText></div>
                    </div>
                    <div className="spacerMed"/>
                    <Button style={buttonStyles} size="large" variant="outlined" href="/create-personalised-pet-photo-gifts">CREATE NOW</Button>

                    <div className="spacer"></div>
                    <div className="jpDivider bgBlue"/>

                </Container>


                <ResponsiveGridLayout className="layout"
                                      layouts={this.state.layouts}
                                      breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 320}}
                                      cols={{lg: 10, md: 10, sm: 6, xs: 2, xxs: 2}}
                                      rowHeight={this.state.rowHeight}
                                      useCSSTransforms={true}
                                      isDraggable={false}
                                      isResizable={false}
                                      autoSize={true}
                                      verticalCompact={true}
                    // margin={[10, 1]}
                                      onWidthChange={this.onWidthChange}
                >

                    {this.generateDOM()}
                </ResponsiveGridLayout>
                <Container disableGutters={true} maxWidth={false}>
                    <div className="jpDivider bgGray"/>

                    {/*<Container maxWidth="lg">*/}

                    <Grid container>
                        <Grid item xs={12} md={6} className="bgGreen">
                            <CenterGrid>
                                <Grid>
                                    <div className="spacer desktopNone"/>
                                    <div className="spacerTiny"/>
                                    <div><h3>DOWNLOAD THE APP</h3></div>
                                    <div className="spacerTiny"/>
                                    <div className="body">Play the Jigsaw puzzle game</div>
                                    <div className="spacerMed"/>
                                    <a onClick={onGotoAppStore}
                                        alt="Apple App Store logo"
                                        href="https://apps.apple.com/gb/app/jigpaws/id1541336283" 
                                        target="_blank">
                                        <img 
                                            src="/images/APP-STORE-LOGO.png" 
                                            style={{"height": "60px"}}
                                        />
                                    </a>


                                    <div className="spacer desktopNone"/>

                                </Grid>
                            </CenterGrid>
                        </Grid>
                            <SliderSection />
                    </Grid>

                </Container>
            </>

        )
    }
}

export default MyResponsiveGrid





