import React from 'react';
import Link from '@mui/material/Link';
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { Divider } from "@mui/material";
import useStyles_footer from './useStyles_Footer';
import { socialLinks, legalLinks } from './footerData';
import TrustPilotWidget from './TrustPilotWidget';
import PaymentInfo from './PaymentInfo';
import {BigP} from "../../styledComponents"

//suspense component


export default function Footer() {
  const classes = useStyles_footer();

  return (
    <div className={classes.root}>
      <footer>
        <div className="FooterSocial bgGray">
          <div className="spacer"></div>
          <div><h3>Follow us</h3></div>
          <BigP className="body leader">on Facebook, Instagram, TikTok, Twitter and YouTube<br />for daily updates, promotions and more...</BigP>
          <div className="spacerMed"></div>
          <div>
            {socialLinks.map((link, index) => (
              <div key={index} className={classes.item}>
                <Link rel="noopener" target="_blank" href={link.href} underline="hover">
                  <img className={classes.socialIcon} src={link.src} alt={link.alt} />
                </Link>
              </div>
            ))}
          </div>
          <div className="spacer"></div>
        </div>
        <Divider className={classes.footerLinebreak}></Divider>
        <Paper elevation={0} square={true} className={classes.legal}>
          <div>
            {[legalLinks.slice(0, 3), legalLinks.slice(3)].map((rowLinks, rowIndex) => (
              <div key={rowIndex} className={classes.legalLinks}>
                {rowLinks.map((link, index) => (
                  <div key={index} className={classes.item}>
                    <Link
                      underline="none"
                      className={classes.legalLink}
                      variant="h6"
                      color="secondary"
                      rel="noopener"
                      target="_blank"
                      href={link.href}
                    >
                      {link.text}
                    </Link>
                  </div>
                ))}
              </div>
            ))}
          </div>
            <PaymentInfo />
            <TrustPilotWidget />
          <div className={classes.brand}>
            <Typography variant={"body1"} paragraph>{'© '}
              {new Date().getFullYear()}
              {' The Lemonade Stall Ltd'}
            </Typography>
          </div>
        </Paper>
      </footer>
    </div>
  );
}
