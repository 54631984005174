
import React from "react";
import {makeStyles} from "@mui/styles";
import {useProductPreviewImage} from "../../storageUtils";


const useStyles = makeStyles({
    previewImage: {
      width: "100%",
      height: "100%",

    },
});

const ProductPreview = ({user, jigpaw, productPreview, size=512}) => {
    const classes = useStyles();

    const {url } = useProductPreviewImage(user, jigpaw.oid, jigpaw.uuid, productPreview, size);


    if (url) {
        return (
            <img className={classes.previewImage} src={url} alt=""/>
        );
    }
    // if (fallbackUrl && urlError) {
    //     return (
    //         <img className={classes.previewImage} src={fallbackUrl} alt=""/>
    //     )
    // }
    return (
        null
    )
};

export default ProductPreview;