// REACT
import React, { useContext } from "react";

// INTERNAL IMPORTS
import { PhotobookStyleContext } from "../../utils";

// EXTERNAL IMPORTS
import { useDraggable } from "muuri-react";

// HOOKS, UTILS, DATA
import { useStyles } from "./useStyles";


const HiddenPage = () => {
    const classes = useStyles();
    const {style} = useContext(PhotobookStyleContext);
    const setDraggable = useDraggable();
    setDraggable(false);
    return (
        <div style={style} className={classes.mobileFix}>
            <div

            >
            </div>
        </div>
    );
}

export default HiddenPage;