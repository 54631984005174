/* React */
import React from "react";
import Grid from "@material-ui/core/Grid";
import {useParams} from "react-router-dom";
import {Square} from "./global/components/Square";
import {Container} from "@mui/material";
import Button from "@mui/material/Button";
import BalanceText from "react-balance-text";
import Hidden from "@mui/material/Hidden";
import Helmet from "react-helmet";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./firebase";
import Link from '@mui/material/Link';
import ShippingFooter from "./ShippingFooter/ShippingFooter";
import CenterGrid from "./CenterGrid";
import { petData } from "./data/giftData";
import ProductPrice from "./useProductPrice";
import { useStyles } from "./utils";
import { BigBody, Body } from "./styledComponents";

//h1 wrap changes hook here
import useIsMobile from "./useIsMobile";


const buttonStyles = {
    padding: '10px 30px',
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0.05em",
    border: "0.05em solid #3E3C3D",
};

const pStyles = {
    fontSize: '18px',
    '@media (maxWidth: 600px)': {
        fontSize: '16px',
        
    },
}




const GiftPage = () => {
    const classes = useStyles();
    //h1 wrap changes
    const isMobile = useIsMobile();

    const [user, authLoading, authError] = useAuthState(auth);

    const {petType} = useParams();

    const data = petData[petType];


    const structuredData = {
    '@context': 'http://schema.org',
    '@type': 'itemList',
    name: data.structured.name,
    description: data.meta.description,
    url: window.location.href,
    numberOfItems: "6",
    itemListElement: [
        {
            type: "ListItem",
              "position": "1",
              "url": "https://www.jigpaws.com/product/jigsaw",
              "name": "Jigsaw"
        },
         {
            type: "ListItem",
              "position": "2",
              "url": "https://www.jigpaws.com/product/cushion",
              "name": data.cushion.title
        },
         {
            type: "ListItem",
              "position": "3",
              "url": "https://www.jigpaws.com/product/photobook",
              "name": data.photobook.title
        },
         {
            type: "ListItem",
              "position": "4",
              "url": "https://www.jigpaws.com/product/photo-tile",
              "name": data.tile.title
        },
         {
            type: "ListItem",
              "position": "5",
              "url": "https://www.jigpaws.com/product/journal",
              "name": data.notebook.title
        },
         {
            type: "ListItem",
              "position": "6",
              "url": "https://www.jigpaws.com/product/card",
              "name": data.card.title
        },
    ]
  };


    return <>
            <Helmet>
                 {/* Add structured data using Helmet */}
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
                <title>{data.head.title}</title>
                <meta name="description" content={data.meta.description}/>
                {/*<link rel="canonical" href="https://www.jigpaws.com/products-personalised-gifts"/>*/}
                <meta property="og:url" content={data.meta.og_url}/>
                <meta property="og:type" content="article"/>
                <meta property="og:title" content={data.meta.og_title}/>
                <meta property="og:description"
                      content={data.meta.og_description}/>
                <meta property="og:image" content={data.meta.og_image}/>
            </Helmet>

            <Container className={"productsHome bgGray"} align="center" disableGutters={true} maxWidth={false}>
                <div className="spacer"/>
                <div className="headerContainer">


                    {/* H1 MOBILE WRAP CHANGES */}
                    {/* <div><h1><BalanceText>{data.header.title}</BalanceText></h1></div> */}


                    <h1 className={isMobile ? 'mobile-title' : 'balance-title'}>
                        {isMobile ? (

                        data.header.title
                        ) : (

                        <BalanceText>{data.header.title}</BalanceText>
                        )}
                    </h1>


                    
                    <BigBody className="body">{data.header.description}</BigBody>
                    {data.header.description2 && <>
                        <div className="spacerSmall"/>
                        <p style={pStyles}className="body"><BalanceText>{data.header.description2}</BalanceText> </p>
                    </>}
                </div>
                <div className="spacer"/>
                <div className="jpDivider bgBlue"/>
            </Container>



            <Container maxWidth="xxl" disableGutters={true} spacing={0}>
                <Grid container spacing={0} className="marginBottomFix">
                    <Grid item xs={12} md={6} order={{xs: 2, sm: 2}} className={classes.moveToFront}>
                        <Square>
                            <img className="jpLine" src={data.jigsaw.image.src}
                                 alt={data.jigsaw.image.alt} style={{"width": "100%"}}/>
                        </Square>
                    </Grid>
                    <Grid item xs={12} md={6} className="jpTile bgWhite" order={{xs: 1, sm: 1}}>
                        <Hidden smUp={true}>
                            <CenterGrid>
                                <Grid>
                                    <div className="jpTileInner">
                                        <Link
                                            rel="canonical"
                                            href={`/gift-products/${data.constant.type}-jigsaws`}
                                            underline="hover"><h2>{data.jigsaw.title}</h2></Link>
                                        <div className="spacerMed"/>
                                        <div className="body">
                                            <p sx={pStyles}>{data.jigsaw.description}</p>
                                            <div className="spacerSmall"/>
                                            <p sx={pStyles}>Available in two sizes:</p>
                                            <div className="spacerSmall"/>
                                            <p sx={pStyles}>• 500pc (50x38cm)</p>
                                            <p sx={pStyles}>• 1000pc (66x50cm)</p>
                                            <div className="spacerSmall"/>
                                            <p sx={pStyles}>It has never been easier to order a personalised jigsaw with hassle-free
                                                shipping today!</p>
                                        </div>
                                        <div className="spacerSmall"/>
                                        <ProductPrice user={user} productId={"jigsaw-500-50x38"}
                                                      startingFrom={true}></ProductPrice>
                                        <div className="spacerMed"/>
                                        <Button size="small" variant="outlined" sx={buttonStyles}
                                                href="/product/jigsaw">CREATE NOW</Button>
                                    </div>
                                    <div className="productPriceDisclaimer"/>
                                    <div className="spacerMed"/>
                                </Grid>
                            </CenterGrid>
                        </Hidden>
                        <Hidden smDown={true}>
                            <Square>
                                <div className="productPriceDisclaimer jpProductFooter"></div>
                                <CenterGrid>
                                    <div className="jpTileInner jpProductInner">
                                        <Link
                                            rel="canonical"
                                            href={`/gift-products/${data.constant.type}-jigsaws`}
                                            underline="hover"><h2>{data.jigsaw.title}</h2></Link>
                                        <div className="spacerMed"/>
                                        <div className="body">
                                            <p sx={pStyles}><BalanceText>{data.jigsaw.description}</BalanceText></p>
                                            <div className="spacerSmall"/>
                                            <p sx={pStyles}>Available in two sizes:</p>
                                            <div className="spacerSmall"/>
                                            <p sx={pStyles}>• 500pc (50x38cm)</p>
                                            <p sx={pStyles}>• 1000pc (66x50cm)</p>
                                            <div className="spacerSmall"/>

                                            <p sx={pStyles}><BalanceText>It has never been easier to order a personalised jigsaw with
                                                hassle-free shipping today!</BalanceText></p>

                                        </div>
                                        <div className="spacerSmall"/>

                                        <ProductPrice user={user} productId={"jigsaw-500-50x38"}
                                                      startingFrom={true}></ProductPrice>
                                        <div className="spacerMed"/>
                                        
                                        <Button size="small" variant="outlined" sx={buttonStyles}
                                                href="/product/jigsaw">CREATE NOW</Button>

                                    </div>
                                </CenterGrid>
                            </Square>
                        </Hidden>
                    </Grid>
                </Grid>
                {/*<div className="spacerMed"/>*/}
                <Grid container spacing={0} className="marginBottomFix">
                    <Grid item xs={12} md={6} className="jpTile bgWhite">
                        <Hidden smUp={true}>
                            <CenterGrid>
                                <Grid>
                                    <div className="jpTileInner">
                                        <Link
                                            rel="canonical"
                                            href={`/gift-products/${data.constant.type}-cushions`}
                                            underline="hover"><h2>{data.cushion.title}</h2></Link>
                                        <div className="spacerMed"/>
                                        <div className="body">
                                            <p sx={pStyles}>{data.cushion.description}</p>
                                            <div className="spacerSmall"/>
                                            <p sx={pStyles}>Available in two sizes:</p>
                                            <div className="spacerSmall"/>
                                            <p sx={pStyles}>• 30x30cm</p>
                                            <p sx={pStyles}>• 46x46cm </p>
                                            <div className="spacerSmall"/>
                                            <p sx={pStyles}>{data.cushion.description2}</p>
                                        </div>
                                        <div className="spacerMed"/>
                                        <ProductPrice user={user} productId={"suede-cushion-12x12"}
                                                      startingFrom={true}></ProductPrice>
                                        <div className="spacerMed"/>
                                        <Button size="small" variant="outlined" sx={buttonStyles}
                                                href="/product/cushion">CREATE NOW</Button>

                                    </div>
                                    <div className="productPriceDisclaimer"/>
                                    <div className="spacerMed"/>
                                </Grid>
                            </CenterGrid>
                        </Hidden>
                        <Hidden smDown={true}>
                            <Square>
                                <div className="productPriceDisclaimer jpProductFooter"></div>
                                <CenterGrid>
                                    <div className="jpTileInner jpProductInner">
                                        <Link
                                            rel="canonical"
                                            href={`/gift-products/${data.constant.type}-cushions`}
                                            underline="hover"><h2>{data.cushion.title}</h2></Link>
                                        <div className="spacerMed"/>
                                        <div className="body">
                                            <p sx={pStyles}><BalanceText>{data.cushion.description}</BalanceText></p>
                                            <div className="spacerSmall"/>
                                            <p sx={pStyles}>Available in two sizes:</p>
                                            <div className="spacerSmall"/>
                                            <p sx={pStyles}>• 30x30cm</p>
                                            <p sx={pStyles}>• 46x46cm </p>
                                            <div className="spacerSmall"/>
                                            <p sx={pStyles}><BalanceText>{data.cushion.description2}</BalanceText></p>

                                        </div>
                                        <div className="spacerMed"/>
                                        <ProductPrice user={user} productId={"suede-cushion-12x12"}
                                                      startingFrom={true}></ProductPrice>
                                        <div className="spacerMed"/>
                                        <Button size="small" variant="outlined" sx={buttonStyles}
                                                href="/product/cushion">CREATE NOW</Button>

                                    </div>
                                </CenterGrid>
                            </Square></Hidden>
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.moveToFront}>
                        <Square>
                            <img className="jpLine" src={data.cushion.image.src}
                                 alt={data.cushion.image.alt} style={{"width": "100%"}}/>
                        </Square>
                    </Grid>
                </Grid>
                {/*<div className="spacerMed"/>*/}
                {/*<Grid>BOOK</Grid>*/}
                <Grid container spacing={0} className="marginBottomFix">
                    <Grid item xs={12} md={6} className={classes.moveToFront}>
                        <Square>
                            <img className="jpLine" src={data.photobook.image.src}
                                 alt={data.photobook.image.alt} style={{"width": "100%"}}/>
                        </Square>
                    </Grid>
                    <Grid item xs={12} md={6} className="jpTile bgWhite">
                        <Hidden smUp={true}>
                            <CenterGrid>
                                <Grid>
                                    <div className="jpTileInner">
                                        <Link
                                            rel="canonical"
                                            href={`/gift-products/${data.constant.type}-photo-books`}
                                            underline="hover"><h2>{data.photobook.title}</h2></Link>
                                        <div className="spacerMed"/>
                                        <div className="body">
                                            <p sx={pStyles}>{data.photobook.description}</p>
                                            <div className="spacerSmall"/>
                                            <p sx={pStyles}>• 2.5mm Hardcover with a matte laminate finish</p>
                                            <p sx={pStyles}>• Durable library binding</p>
                                            <p sx={pStyles}>• 170gsm satin-finished, sustainably sourced paper</p>
                                            <p sx={pStyles}>• 20 customisable pages per book</p>
                                            {/* <div className="spacerSmall"/> */}
                                        </div>
                                        <div className="spacerMed"/>
                                        <ProductPrice user={user} productId={"photobook-14x14"}
                                                      startingFrom={true}></ProductPrice>
                                        <div className="spacerMed"/>
                                        <Button size="small" variant="outlined" sx={buttonStyles}
                                                href="/product/photobook">CREATE NOW</Button>

                                    </div>
                                    <div className="productPriceDisclaimer"/>
                                    <div className="spacerMed"/>
                                </Grid>
                            </CenterGrid>
                        </Hidden>
                        <Hidden smDown={true}>
                            <Square>
                                <div className="productPriceDisclaimer jpProductFooter"></div>
                                <CenterGrid>
                                    <div className="jpTileInner jpProductInner">
                                        <Link
                                            rel="canonical"
                                            href={`/gift-products/${data.constant.type}-photo-books`}
                                            underline="hover"><h2>{data.photobook.title}</h2></Link>
                                        <div className="spacerMed"/>
                                        <div className="body">
                                            <p sx={pStyles}><BalanceText>{data.photobook.description}</BalanceText></p>
                                            <div className="spacerSmall"/>
                                            <p sx={pStyles}>• 2.5mm Hardcover with a matte laminate finish</p>
                                            <p sx={pStyles}>• Durable library binding</p>
                                            <p sx={pStyles}>• 170gsm satin-finished, sustainably sourced paper</p>
                                            <p sx={pStyles}>• 20 customisable pages per book</p>
                                        </div>
                                        <div className="spacerMed"/>
                                        <ProductPrice user={user} productId={"photobook-14x14"}
                                                      startingFrom={true}></ProductPrice>
                                        <div className="spacerMed"/>
                                        <Button size="small" variant="outlined" sx={buttonStyles}
                                                href="/product/photobook">CREATE NOW</Button>
                                    </div>
                                </CenterGrid>
                            </Square></Hidden>
                    </Grid>

                </Grid>
                {/*<Grid>CUSHIONS</Grid>*/}
                <Grid container spacing={0} className="marginBottomFix">
                    <Grid item xs={12} md={6} className="jpTile bgWhite">
                        <Hidden smUp={true}>
                            <CenterGrid>
                                <Grid>
                                    <div className="jpTileInner">
                                        <Link
                                            rel="canonical"
                                            href={`/gift-products/${data.constant.type}-photo-tiles`}
                                            underline="hover"><h2>{data.tile.title}</h2></Link>
                                        <div className="spacerMed"/>
                                        <div className="body">
                                            <p sx={pStyles}>{data.tile.description}</p>
                                        </div>
                                        <div className="spacerMed"/>
                                        <ProductPrice user={user} productId={"photo-tile"}></ProductPrice>

                                        <div className="spacerMed"/>
                                        <Button size="small" variant="outlined" sx={buttonStyles}
                                                href="/product/photo-tile">CREATE NOW</Button>
                                    </div>
                                    <div className="productPriceDisclaimer"/>
                                    <div className="spacerMed"/>
                                </Grid>
                            </CenterGrid>
                        </Hidden>
                        <Hidden smDown={true}>
                            <Square>
                                <div className="productPriceDisclaimer jpProductFooter"></div>
                                <CenterGrid>
                                    <div className="jpTileInner jpProductInner">
                                        <Link
                                            rel="canonical"
                                            href={`/gift-products/${data.constant.type}-photo-tiles`}
                                            underline="hover"><h2>{data.tile.title}</h2></Link>
                                        <div className="spacerMed"/>
                                        <div className="body">
                                            <p sx={pStyles}><BalanceText>{data.tile.description}</BalanceText></p>
                                        </div>
                                        <div className="spacerMed"/>
                                        <ProductPrice user={user} productId={"photo-tile"}></ProductPrice>
                                        <div className="spacerMed"/>
                                        <Button size="small" variant="outlined" sx={buttonStyles}
                                                href="/product/photo-tile">CREATE NOW</Button>
                                    </div>
                                </CenterGrid>
                            </Square></Hidden>
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.moveToFront}>
                        <Square>
                            <img className="jpLine" src={data.tile.image.src}
                                 alt={data.tile.image.alt} style={{"width": "100%"}}/>
                        </Square>
                    </Grid>
                </Grid>
                {/*<Grid>JIGSAWS</Grid>*/}

                {/*<div className="spacerMed"/>*/}
                <Grid container spacing={0} className="marginBottomFix">
                    <Grid item xs={12} md={6} className={classes.moveToFront}>
                        <Square>
                            <img className="jpLine" src={data.notebook.image.src}
                                 alt={data.notebook.image.alt}
                                 style={{"width": "100%"}}/>
                        </Square>
                    </Grid>
                    <Grid item xs={12} md={6} className="jpTile bgWhite">
                        <Hidden smUp={true}>
                            <CenterGrid>
                                <Grid>
                                    <div className="jpTileInner">

                                        <Link
                                            rel="canonical"
                                            href={`/gift-products/${data.constant.type}-notebooks`}
                                            underline="hover"><h2>{data.notebook.title}</h2></Link>
                                        <div className="spacerMed"/>
                                        <div className="body">
                                            <p sx={pStyles}>{data.notebook.description}</p>
                                            <div className="spacerSmall"/>
                                            <p sx={pStyles}>{data.notebook.description2}</p>
                                            <div className="spacerSmall"/>
                                            <p sx={pStyles}>• Plain pages</p>
                                            <p sx={pStyles}>• Lined pages</p>
                                            <p sx={pStyles}>• Graph pages</p>
                                        </div>
                                        <div className="spacerMed"/>
                                        <ProductPrice user={user} productId={"plain-journal"}></ProductPrice>
                                        <div className="spacerMed"/>
                                        <Button size="small" variant="outlined" sx={buttonStyles}
                                                href="/product/journal">CREATE NOW</Button>
                                    </div>
                                    <div className="productPriceDisclaimer"/>
                                    <div className="spacerMed"/>
                                </Grid>
                            </CenterGrid>
                        </Hidden>
                        <Hidden smDown={true}>
                            <Square>
                                <div className="productPriceDisclaimer jpProductFooter"></div>
                                <CenterGrid>
                                    <div className="jpTileInner jpProductInner">
                                        <Link
                                            rel="canonical"
                                            href={`/gift-products/${data.constant.type}-notebooks`}
                                            underline="hover"><h2>{data.notebook.title}</h2></Link>
                                        <div className="spacerMed"/>
                                        <div className="body">
                                            <p sx={pStyles}><BalanceText>{data.notebook.description}</BalanceText></p>
                                            <div className="spacerSmall"/>
                                            <p sx={pStyles}><BalanceText>{data.notebook.description2}</BalanceText></p>
                                            <div className="spacerSmall"/>
                                            <p sx={pStyles}>• Plain pages</p>
                                            <p sx={pStyles}>• Lined pages</p>
                                            <p sx={pStyles}>• Graph pages</p>

                                        </div>
                                        <div className="spacerMed"/>
                                        <ProductPrice user={user} productId={"plain-journal"}></ProductPrice>
                                        <div className="spacerMed"/>
                                        <Button size="small" variant="outlined" sx={buttonStyles}
                                                href="/product/journal">CREATE NOW</Button>

                                    </div>
                                </CenterGrid>
                            </Square></Hidden>
                    </Grid>

                </Grid>
                <Grid container spacing={0} className="marginBottomFix">
                    <Grid item xs={12} md={6} className="jpTile bgWhite">
                        <Hidden smUp={true}>
                            <CenterGrid>
                                <Grid>
                                    <div className="jpTileInner">
                                        <Link
                                            rel="canonical"
                                            href={`/gift-products/${data.constant.type}-cards`}
                                            underline="hover"><h2>{data.card.title}</h2></Link>
                                        <div className="spacerMed"/>
                                        <div className="body">
                                            <p sx={pStyles}>{data.card.description}</p>
                                            <div className="spacerSmall"/>
                                            <p sx={pStyles}>Finishes available:</p>
                                            <div className="spacerSmall"/>
                                            <p sx={pStyles}>• Matte, 324gsm (heavyweight)</p>
                                            <p sx={pStyles}>• Gloss, 324gsm (heavyweight)</p>
                                            <div className="spacerSmall"/>
                                            <p sx={pStyles}>{data.card.description2}</p>
                                        </div>
                                        <div className="spacerMed"/>
                                        <ProductPrice user={user} productId={"gloss-greeting-card"}></ProductPrice>
                                        <div className="spacerMed"/>
                                        <Button size="small" variant="outlined" sx={buttonStyles}
                                                href="/product/greeting-card">CREATE NOW</Button>

                                        <div className="spacerMed"/>

                                    </div>
                                    <div className="productPriceDisclaimer"/>
                                    <div className="spacerMed"/>
                                </Grid>
                            </CenterGrid>
                        </Hidden>
                        <Hidden smDown={true}>
                            <Square>
                                <div className="productPriceDisclaimer jpProductFooter"></div>
                                <CenterGrid>
                                    <div className="jpTileInner jpProductInner">
                                        <Link
                                            rel="canonical"
                                            href={`/gift-products/${data.constant.type}-cards`}
                                            underline="hover"><h2>{data.card.title}</h2></Link>
                                        <div className="spacerMed"/>
                                        <div className="body">
                                            <p sx={pStyles}><BalanceText>{data.card.description}</BalanceText></p>
                                            <div className="spacerSmall"/>
                                            <p sx={pStyles}><BalanceText>Finishes available:</BalanceText></p>
                                            <div className="spacerSmall"/>
                                            <p sx={pStyles}>• Matte, 324gsm (heavyweight)</p>
                                            <p sx={pStyles}>• Gloss, 324gsm (heavyweight)</p>
                                            <div className="spacerSmall"/>
                                            <p sx={pStyles}><BalanceText>{data.card.description2}</BalanceText></p>
                                            {/*<div className="spacerSmall"/>*/}
                                            {/*<p sx={pStyles}><BalanceText>Great for coffee tables, these glossy, hardback photo albums are printed on thick, durable card. This ensures that your personalised pet pic will always look detailed and high quality for years to come.</BalanceText></p>*/}

                                        </div>
                                        <div className="spacerMed"/>
                                        <ProductPrice user={user} productId={"gloss-greeting-card"}></ProductPrice>
                                        <div className="spacerMed"/>
                                        <Button size="small" variant="outlined" sx={buttonStyles}
                                                href="/product/greeting-card">CREATE NOW</Button>
                                    </div>
                                </CenterGrid>
                            </Square></Hidden>
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.moveToFront}>
                        <Square>
                            <img className="jpLine" src={data.card.image.src}
                                 alt={data.card.image.alt}
                                 style={{"width": "100%"}}/>
                        </Square>
                    </Grid>
                </Grid>
                {/*<Grid>END</Grid>*/}

                {/*<div className="spacerMed"/>*/}

            </Container>
            <ShippingFooter international={true} />
        </>;
};

export default GiftPage
