import {useState, useEffect} from "react";
import { ref, getDownloadURL } from "firebase/storage";
import {anonymousBucket, bucketForUser, defaultBucket, publicBucket} from "./firebase";
import _ from "lodash";

import {createUrl} from "./API";

const anonymousMainRef = (userId, jigpawId) => {
    return ref(anonymousBucket, `${userId}/${jigpawId}/512.jpg`);
};

export const useMainImage = (user, oid, uuid, jigpaw) =>  {
    const [url, setUrl] = useState();
    const [error, setError] = useState();

    useEffect(() => {
        setError(null);

        if (!oid || !uuid) {
            return;
        }

        // not the owner or signed in aka public image.
        if (oid !== user.uid || !user.isAnonymous) {
            setUrl(`https://storage.googleapis.com/${process.env.REACT_APP_PUBLIC_BUCKET}/jigpaws/${oid}/${uuid}/512.jpg`);
            return;
        }

        // user must be anonymous
        getDownloadURL(anonymousMainRef(user.uid, uuid))
            .then((ret) => {
                setUrl(ret);
            })
            .catch((error) => {
                if (jigpaw) {
                    setUrl("https://storage.googleapis.com/jigpaws_public/jigpaws/does-not-exist.jpg")
                }
                setError(error);
            });

    },[user.uid, user.isAnonymous, oid, uuid, jigpaw]);

    return {url, error};
};


export const useProductPreviewImage = (user, oid, jigpawId, productPreview, size= 512) => {
    // console.log("oid",oid, "user", user);
    // oid = oid | user.uid;
    // console.log("oid",oid)
    const [url, setUrl] = useState();
    const [error, setError] = useState();

    useEffect(() => {
        setError(null);

        if (oid !== user.uid || !user.isAnonymous) {
            setUrl(createUrl(`image/product/${productPreview}/${oid}/${jigpawId}/${size}`));
            return;
        }

        const bucket = (user.isAnonymous) ? defaultBucket : publicBucket;
        getDownloadURL(ref(bucket, `jigpaws/${oid}/${jigpawId}/${productPreview}_${size}.jpg`))
            .then((ret) => {
                setUrl(ret);
            })
            .catch((error) => {
                getDownloadURL(anonymousMainRef(user.uid, jigpawId))
                    .then((ret) => {
                        setUrl(ret);
                    })
                    .catch((error) => {
                        setError(error);
                    });
            });

    }, [user.uid, user.isAnonymous, oid, jigpawId, productPreview]);

    return {url, error};
};


export const useOriginalImage = (user, jigpaw) => {

    const [url, setUrl] = useState();
    const [error, setError] = useState();

    useEffect(() => {
        setError(null);

        const oid = _.get(jigpaw, "oid", jigpaw.uid);
        // system image.
        if (oid === 'system') {
            const url = createUrl(`image/system/${jigpaw.uuid}`)
            setUrl(url)
            return
        }

        const bucket = bucketForUser(user);
        let path = `${user.uid}/${jigpaw.uuid}/${jigpaw.original}`;

        getDownloadURL(ref(bucket, path))
            .then((ret) => {
                setUrl(ret);
            })
            .catch((error) => {
                setError(error);
            });

    },[user.oid, user.uid, user.isAnonymous, jigpaw.uuid]);

    return {url, error};
};
