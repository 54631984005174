// ShippingFooter.js
import React from "react";
import { Container, Grid } from "@mui/material";
import ShippingInfo from "./ShippingInfo";
import SliderSection from "./SliderSection";

export default function ShippingFooter({international, includeCustomAppleSection}) {

    return (
        <Container disableGutters={true} maxWidth={false}>
            <div className="jpDivider bgGray" />
            <Grid container>
                <ShippingInfo international={international} />
                <SliderSection includeCustomAppleSection={includeCustomAppleSection}/>
            </Grid>
        </Container>
    );
}
