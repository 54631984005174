// BlogItem.jsx
import React from "react";

const BlogItem = ({ title, imageSrc, imageAlt, link, description }) => {
  return (
    <div className="jpBlogsItem">
      <div className="jpBlogsItemImageCont">
        <a href={link}>
          <img src={imageSrc} alt={imageAlt} />
        </a>
      </div>
      <div className="jpBlogsItemTextCont">
        <div className="jpBlogsItemText">
          <h2><a href={link}>{title}</a></h2>
          <p><a href={link}>{description}</a></p>
        </div>
      </div>
    </div>
  );
};

export default BlogItem;
