// blogPages.js
// import BlogPage from "./blog/BlogPage";
import BlogPage from "./BlogPage";
import BlogPage2 from "./BlogPage2";
import BlogPage3 from "./BlogPage3";
import BlogPage4 from "./BlogPage4";

const blogPages = [
  { path: "/blog/make-a-birthday-card-for-a-cat/", component: BlogPage },
  { path: "/blog/top-10-gifts-for-dog-lovers-in-2024/", component: BlogPage2 },
  { path: "/blog/dog-birthday-gift-inspiration/", component: BlogPage3 },
  { path: "/blog/mothers-day-dog-gift/", component: BlogPage4 },
  
];

export default blogPages;
