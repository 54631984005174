export const petData = {
    'cats': {
        'constant': {
            'type': 'cat',
        }, 'head': {
            'title': 'Personalised Cat Gifts | Free Shipping | Jigpaws',
        }, 'structured': {
            'name': 'Personalised Cat Gifts',
            'image': 'http://localhost:3000/images/products/cathome2023/products-cat-home-jigsaw.jpg',
        }, 'meta': {
            'description': 'Upload a picture of your cat to any jigsaws, cards, cushions and more from Jigpaws. Shipping is free for the UK or available overnight and internationally.',
            'og_url': 'https://www.jigpaws.com/gifts/cats"',
            'og_title': 'Personalised Cat Gifts | Free Shipping | Jigpaws',
            'og_description': 'Upload a picture of your cat to any jigsaws, cards, cushions and more from Jigpaws. Shipping is free for the UK or available overnight and internationally.',
            'og_image': 'https://www.jigpaws.com/images/Jigpaws-social-2.jpg',
        }, 'header': {
            'title': 'Create Personalised Cat Gifts With Jigpaws',
            'description': 'Here at Jigpaws, we create custom-made gifts featuring your favourite feline friends! Simply find the perfect gift and instantly upload your image to see how your cat will appear on one of our personalised cat gifts from Jigpaws.',
            'description2': 'Once you have completed your fully customisable and personalised cat gifts, you can ship them absolutely free to anywhere in the UK!'
        }, 'jigsaw': {
            'title': 'PERSONALISED CAT JIGSAWS',
            'description': 'Creating a fully personalised cat jigsaw has never been easier than with our quick editing tool. Simply upload a picture of your beloved cat to a Jigpaws jigsaw and receive free international shipping at checkout!',
            'image': {
                'src': '/images/products/cathome2023/products-cat-home-jigsaw.jpg',
                'alt': 'Jigpaws personalised gift jigsaw with pet cat photo'
            },
        }, 'cushion': {
            'title': 'PERSONALISED CAT CUSHIONS',
            'description': 'Your cat will love snuggling up with their very own personalised  cat cushion. Simply upload your image to one of our soft-touch  faux-suede cushions and see how great it will look. Click below  to start creating your personalised cat cushions with Jigpaws.',
            'description2': 'Each size is made from a removable, cover with a  machine-washable, synthetic, interior filling. Our  personalised cat cushions would be perfect for lazy days  on the sofa with family or friends or as part of a loving gift.',
            'image': {
                'src': '/images/products/cathome2023/products-cat-home-cushion.jpg',
                'alt': 'Jigpaws personalised gift cushion with pet cat photo'
            }
        }, 'photobook': {
            'title': 'PERSONALISED CAT PHOTOBOOKS',
            'description': 'What better way to cherish the memories of your beloved cat than with a high-quality, fully customisable and personalised cat photo book? Amazing for storing cherished memories, our personalised cat photo books are printed using vibrant colours on a hard, gloss-finished cover. Available in 2 sizes (14x14cm or 21x21cm), your personalised cat photo book will come with:',
            'image': {
                'src': '/images/products/cathome2023/products-cat-home-photobook.jpg',
                'alt': 'Jigpaws personalised photobook with pet cat photo'
            }
        }, 'tile': {
            'title': 'PERSONALISED CAT PHOTO TILES',
            'description': 'Creating strong and detailed pieces of art has never been easier than with our personalised pet photo tiles here at Jigpaws. Simply upload your image to our site and see how your pet looks in one of our 20x20cm, black framed photo frames.',
            'image': {
                'src': '/images/products/cathome2023/products-cat-home-phototile.jpg',
                'alt': 'Jigpaws personalised gift photo tile with pet cat'
            }
        }, 'notebook': {
            'title': 'PERSONALISED CAT NOTEBOOKS',
            'description': 'If you or a loved one is starting school or university, what better way to remind them of home than with a personalised cat notebook? We think these fully customisable cat notebooks would also be great for journaling and jotting down notes at work or during a hobby! Our personalised cat notebooks are made with the highest-quality materials, including 64 pages and feature a hardback, glossy finish.',
            'description2': 'They can be ordered with your option of:',
            'image': {
                'src': '/images/products/cathome2023/products-cat-home-journal.jpg',
                'alt': 'Jigpaws personalised gift notebook with pet cat photo'
            }
        }, 'card': {
            'title': 'PERSONALISED CAT CARDS',
            'description': 'Customising and sending fully personalised cat cards has never been easier than with us here at Jigpaws! Just upload your picture and see how it would look on one of our heavyweight, 324gsm cards and choose from a wide range of fonts and emojis to make it fun. Each also has a blank interior page.',
            'description2': 'At Jigpaws, we offer free shipping on all our cards, it has never been easier to get strong, detailed and fully personalised cat cards delivered straight to your door!',
            'image': {
                'src': '/images/products/cathome2023/products-cat-home-card.jpg',
                'alt': 'Jigpaws personalised gift photo card with pet cat photo'
            }
        },
    },
    'dogs': {
        'constant': {
            'type': 'dog',
        },
        'head': {
            'title': 'Personalised Dog Gifts | Free Shipping | Jigpaws',
            'name': 'Personalised Dog Gifts'
            }, 'structured': {
            'name': 'Personalised Dog Gifts',
            'image': 'http://localhost:3000/images/products/cathome2023/products-dog-home-jigsaw.jpg',
        }, 'meta': {
            'description': 'Add a personalised picture of a dog to cushions, jigsaws, cards, notebooks and photo tiles. Free Shipping for the UK from Jigpaws.',
            'og_url': 'https://www.jigpaws.com/gifts/dogs',
            'og_title': 'Personalised Dog Gifts | Free Shipping | Jigpaws',
            'og_description': 'Add a personalised picture of a dog to cushions, jigsaws, cards, notebooks and photo tiles. Free Shipping for the UK from Jigpaws.',
            'og_image': 'https://www.jigpaws.com/images/Jigpaws-social-2.jpg',
        }, 'header': {
            'title': 'CREATE AND CUSTOMISE PERSONALISED DOG GIFTS',
            'description': 'Here at Jigpaws, we create custom-made gifts featuring your favourite four-legged friends! Simply find the perfect gift and instantly upload your image to see how your dog will appear on one of our many personalised dog gifts from Jigpaws.',
            'description2': ''
        }, 'jigsaw': {
            'title': 'PERSONALISED DOG JIGSAWS',
            'description': 'Creating a fully personalised dog jigsaw has never been easier than with our quick editing tool. Simply upload a picture of your dog to a Jigpaws jigsaw and receive free international shipping once you order!',
            'image': {
                'src': '/images/products/doghome2023/products-dog-home-jigsaw.jpg',
                'alt': 'Jigpaws personalised gift jigsaw with pet dog photo'
            }
        }, 'cushion': {
            'title': 'PERSONALISED DOG CUSHIONS',
            'description': 'Our personalised dog cushions are perfect for any sofa, arm  chair, bed or even for your dog’s own space. Simply upload  your picture to one of our soft-touch faux-suede cushions  and see it instantly! Click below to start creating your  personalised dog cushions with Jigpaws.',
            'description2': 'Each personalised dog cushion is made from  a 100% soft-touch suede cover with a  machine washable and synthetic plush interior filling.',
            'image': {
                'src': '/images/products/doghome2023/products-dog-home-cushion.jpg',
                'alt': 'Jigpaws personalised gift cushion with pet dog photo'
            }
        }, 'photobook': {
            'title': 'PERSONALISED DOG PHOTOBOOKS',
            'description': 'Our personalised dog photo books are great for storing  cherished memories. Each fully personalised dog photo  book is printed using vibrant colours on a gloss-finished,  durable cardboard cover. Available in 2 sizes (14x14cm or  21x21cm), your personalised dog photo book will come with:',
            'image': {
                'src': '/images/products/doghome2023/products-dog-home-photobook.jpg',
                'alt': 'Jigpaws personalised photobook with pet dog photo'
            }
        }, 'tile': {
            'title': 'Personalised Dog Photo Tiles',
            'description': 'Creating strong and detailed pieces of art has never been  easier than with our personalised pet photo tiles here at  Jigpaws. Simply upload your image to our site and see how your  pet looks in one of our 20x20cm, black framed photo frames.',
            'image': {
                'src': '/images/products/doghome2023/products-dog-home-phototile.jpg',
                'alt': 'Jigpaws personalised gift photo tile with pet dog'
            }
        }, 'notebook': {
            'title': 'PERSONALISED DOG NOTEBOOKS',
            'description': '  Bringing a memory of your beloved dog to study or work  has never been easier than with our personalised dog  notebooks from Jigpaws. If you or a loved one is starting  school or university, what better way to remind them  of home than with a personalised dog notebook?   ',
            'description2': 'Our personalised dog notebooks are made with the highest-  quality materials and contain 64 custom pages. Each is made with  a glossy, hardback finish and can be ordered with a choice of:',
            'image': {
                'src': '/images/products/doghome2023/products-dog-home-journal.jpg',
                'alt': 'Jigpaws personalised gift notebook with pet dog photo'
            }
        }, 'card': {
            'title': 'PERSONALISED DOG CARDS',
            'description': 'Simply upload your image to our site and see how it would look  on one of our personalised dog cards. Then you can choose from  a wide range of fonts and emojis to make it fun, creative and  show off your dog’s silly side! The cards come blank on the inside  as standard, allowing you to write your own personal message.',
            'description2': 'With free shipping on all our cards, it has never  been easier to get strong, detailed and fully  personalised dog cards delivered straight to your door.',
            'image': {
                'src': '/images/products/doghome2023/products-dog-home-card.jpg',
                'alt': 'Jigpaws personalised gift photo card with pet dog photo'
            }
        },
    },
    'pets': {
        'constant': {
            'type': 'pet',
        },
        'head': {
            'title': 'Personalised Pet Gifts | Free Shipping | Jigpaws'
            },
            'structured': {
            'name': 'Personalised Pet Gifts',
            'image': 'http://localhost:3000/images/products/cathome2023/products-pet-home-jigsaw.jpg',
        }, 'meta': {
            'description': 'Our large collection of fully customisable pet jigsaws, notebooks, cushions, picture frames and more! Free shipping for the UK or internationally from Jigpaws.',
            'og_url': 'https://www.jigpaws.com/gifts/pets',
            'og_title': 'Personalised Pet Gifts | Free Shipping | Jigpaws',
            'og_description': 'Our large collection of fully customisable pet jigsaws, notebooks, cushions, picture frames and more! Free shipping for the UK or internationally from Jigpaws.',
            'og_image': 'https://www.jigpaws.com/images/Jigpaws-social-2.jpg',
        }, 'header': {
            'title': 'CREATE PERSONALISED PET GIFTS WITH JIGPAWS',
            'description': 'Do you or a loved one have a pet that you want to celebrate? Does\n your best friend have a happy horse, a brilliant budgie or even a\n legendary lizard? If so, then you’re in the best place for celebrating\n these friendly pets by creating a personalised gift in their honour!',
            'description2': 'Jigpaws ships free anywhere in the United Kingdom.\n Global shipping and quick delivery are also available.\n',
        }, 'jigsaw': {
            'title': 'PERSONALISED PET JIGSAWS',
            'description': 'Here at Jigpaws, we create personalised pet jigsaw puzzles\n with pictures of any animal for someone to keep and cherish.',
            'image': {
                'src': '/images/products/pethome2023/products-pet-home-jigsaw.jpg',
                'alt': 'Jigpaws personalised gift jigsaw with pet horses photo'
            }
        }, 'cushion': {
            'title': 'PERSONALISED PET CUSHIONS',
            'description': 'Our cushions are perfect for any sofa or snuggle space\n (but they’d also be great for your pet\'s snuggle space,\n too!) Simply upload your image to one of our soft-\n touch faux-suede cushions with a picture of your\n favourite pet today. We offer cushions in these sizes:',
            'description2': 'Our faux-suede covers are fully removable and machine\n washable and would be perfect for a sofa, bedroom or office.',
            'image': {
                'src': '/images/products/pethome2023/products-pet-home-cushion.jpg',
                'alt': 'Jigpaws personalised gift cushion with pet cat photo'
            }
        }, 'photobook': {
            'title': 'PERSONALISED PET PHOTOBOOKS',
            'description': 'Great for yearbooks, storing holiday memories, or even\n weddings - our personalised pet photo books are\n printed using vibrant colours on a strong cardboard,\n gloss-finished cover. Available in 2 sizes (14x14cm or\n 21x21cm), your personalised pet photo book will come with:',
            'image': {
                'src': '/images/products/pethome2023/products-pet-home-photobook.jpg',
                'alt': 'Jigpaws personalised photobook with pet fish photo'
            }
        }, 'tile': {
            'title': 'PERSONALISED PET PHOTO TILES',
            'description': 'Creating strong and detailed pieces of art has never been\n easier than with our personalised pet photo tiles here at\n Jigpaws. Simply upload your image to our site and see how your\n pet looks in one of our 20x20cm, black framed photo frames.',
            'image': {
                'src': '/images/products/pethome2023/products-pet-home-phototile.jpg',
                'alt': 'Jigpaws personalised gift photo tile with pet toroise'
            }
        }, 'notebook': {
            'title': 'PERSONALISED PET NOTEBOOKS',
            'description': 'Simply upload a picture of you or someone you love’s\n beloved pet and see it on a notebook today! We think\n these fully customisable pet notebooks would also be\n great for journaling or for jotting down notes at work or\n study. Made from the highest-quality materials, our\n personalised pet notebooks can be ordered with your option of:',
            'description2': '',
            'image': {
                'src': '/images/products/pethome2023/products-pet-home-journal.jpg',
                'alt': 'Jigpaws personalised gift notebook with pet bird photo'
            }
        }, 'card': {
            'title': 'PERSONALISED PET CARDS',
            'description': 'Simply upload your picture to our site and see how it\n would look on one of our heavyweight, 324gsm cards\n and choose from a wide range of fonts and emojis. The\n cards come blank on the inside as standard, allowing you\n to write your personal message to friends and family.',
            'description2': 'At Jigpaws, we offer free shipping on all our cards.',
            'image': {
                'src': '/images/products/pethome2023/products-pet-home-card.jpg',
                'alt': 'Jigpaws personalised gift photo card with pet squirrel photo'
            }
        },
    },
}