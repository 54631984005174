import React from "react"
import { ProductCard } from "../../products/components/ProductCard";
import { doc } from "firebase/firestore";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { db } from "../../firebase";
import { jigpawConverter } from "../../models";

const PhotobookProductInner = ({user, photobook, product, frontPage, currency}) => {
    const docId = (frontPage.oid === 'system') ? user.uid + '_' + frontPage.jigpawId : frontPage.jigpawId;
    const [jigpaw ] = useDocumentDataOnce(doc(db, "jigpaws", docId).withConverter(jigpawConverter));

    if (!jigpaw) {
        return (null)
    }
    return (<ProductCard user={user} product={product} jigpaw={jigpaw} noDescription={true} currency={currency}></ProductCard>);
}

export default PhotobookProductInner