//REACT
import React from "react";

//FIREBASE
import { app } from "../../firebase";
import {getAuth} from "firebase/auth";
import {useAuthState} from 'react-firebase-hooks/auth';

//MATERIALS

//INTERNAL IMPORTS

//EXTERNAL IMPORTS
import '@uppy/core/dist/style.css';
import '@uppy/drag-drop/dist/style.css';

//COMPONENTS
import CreateInner from "../components/CreateInner";
import {DelayedLinearProgress} from "../../global/components/ProgressBars";

//HOOKS, UTILS, DATA






const Create = () => {
    const auth = getAuth(app);
    //todo
    // const [user, authLoading, authError] = useAuthState(auth);
    const [user] = useAuthState(auth);

    if (user) {
        return (
            <CreateInner user={user}></CreateInner>
        );
    }
    return (
        <DelayedLinearProgress/>
    );
}


export default Create