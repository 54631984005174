import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    labelContainer: {
        height: "40px",
        textAlign: "left",
        display: "flex",
         flexDirection: 'column',
        justifyContent: 'flex-end',
        fontSize: '20px',
        fontWeight: '400',
    },
    buttonContainer: {
        fontSize: '14px',
        fontWeight: '400',
    },
    mobileFix: {
        touchAction: "pan-y !important"
    }
}));