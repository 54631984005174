const socialLinks = [
    { href: "https://www.facebook.com/jigpaws.co.uk", src: "/images/FACEBOOK.png", alt: "facebook icon" },
    { href: "https://www.instagram.com/jigpaws.co.uk/", src: "/images/INSTAGRAM.png", alt: "instagram icon" },
    { href: "https://www.tiktok.com/@jigpaws.com?lang=en", src: "/images/tiktok.png", alt: "tiktok icon" },
    { href: "https://twitter.com/JigPawsDotCom", src: "/images/TWITTER.png", alt: "twitter icon" },
    { href: "https://www.youtube.com/channel/UCf9sY6a_4RvSl_eP-UX3YZQ?sub_confirmation=1", src: "/images/YOUTUBE.png", alt: "youtube icon" },
  ];
  
  const legalLinks = [
    { href: "/legal/terms-and-conditions", text: "TERMS OF USE" },
    { href: "/legal/privacy-policy", text: "PRIVACY POLICY" },
    { href: "/legal/cookies", text: "COOKIES" },
    { href: "/deliveries-and-returns", text: "DELIVERIES & RETURNS" },
    { href: "mailto:support@jigpaws.com", text: "SUPPORT" },
  ];

  export {socialLinks, legalLinks}