import BasePlugin from '@uppy/core/lib/BasePlugin.js';
import { v4 as uuidv4 } from 'uuid';
import {uploadBytesResumable,  getDownloadURL } from "firebase/storage";


export default class FirebaseCloudStorage extends BasePlugin {
    constructor(uppy, opts) {
        super(uppy, opts);
        if (!opts.storageRef) {
            throw Error(
                "Please provide the root storageRef to be used as option `storageRef`. See https://firebase.google.com/docs/storage/web/upload-files"
            );
        }
        this.type = "uploader";
        this.id = "FirebaseCloudStorage";
        this.title = "Firebase Cloud Storage";
        this.storageRef = opts.storageRef;
        this.uploadFile = this.uploadFile.bind(this);
    }



    uploadFile(fileIds) {
        return Promise.all(
            fileIds.map(id => {
                return new Promise((resolve, reject) => {
                    const file = this.uppy.getFile(id);
                    // console.log(file);
                    // console.log(this.storageRef);
                    file.uuid = uuidv4();
                    const fileRef = this.storageRef(file.uuid, file.extension);
                    // const fileRef = uploadRef(uuidv4(), file.extension);
                    console.log("fileRef", fileRef);
                    const metaData = {
                        contentType: file.type
                    };
                    this.uppy.emit("upload-started", file);

                    const uploadTask = uploadBytesResumable(fileRef, file.data, metaData);
                    uploadTask.on(
                        "state_changed",
                        snapshot => {
                            const progressInfo = {
                                uploader: this,
                                bytesUploaded: snapshot.bytesTransferred,
                                bytesTotal: snapshot.totalBytes
                            };
                            this.uppy.emit("upload-progress", file, progressInfo);
                        },
                        error => {
                            this.uppy.emit("upload-error", file, error);
                            reject(error);
                        },
                        () => {
                            getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
                                console.log('File available at', downloadUrl);
                                const file = this.uppy.getFile(id);
                                file.downloadUrl = downloadUrl;
                                this.uppy.emit(
                                    "upload-success",
                                    file,
                                    uploadTask.snapshot,
                                );
                                resolve();
                            });

                        }
                    );
                });
            })
        );
    }

    install() {
        this.uppy.addUploader(this.uploadFile);
    }

    uninstall() {
        this.uppy.removeUploader(this.uploadFile);
    }
}