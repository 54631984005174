
// REACT
import React, { useContext } from "react";

// INTERNAL IMPORTS
import { PhotobookStyleContext } from "../../utils";

// EXTERNAL IMPORTS
import { useDraggable } from "muuri-react";

// HOOKS, UTILS, DATA
import { useStyles } from "./useStyles";


const Label = ({title}) => {
    const classes = useStyles();
    const {labelStyle} = useContext(PhotobookStyleContext);
    const setDraggable = useDraggable();
    setDraggable(false);

    return (
        <div style={labelStyle} className={classes.mobileFix}>
            <div style={{
                position: 'absolute',
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
            }}>
                <div className={classes.labelContainer}>
                    {title}
                </div>
            </div>
        </div>
    )
}

export default Label;