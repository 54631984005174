 //REACT
 import React from "react"
 import {Link as RouterLink} from "react-router-dom";

 //COMPONENTS
 import Delayed from "../../global/components/Delayed";

 //MATERIALS
 import {CircularProgress} from "@mui/material";
 import Grid from "@mui/material/Grid";
 import Card from '@mui/material/Card';
 import CardActionArea from '@mui/material/CardActionArea';

 const JigpawCardPlaceHolder = (props) => {
    const {jigpaw} = props;

    return (
        <Delayed>
            <Card square elevation={0} variant="outlined"
                  style={{width: "100%", height: "100%", backgroundColor: "white"}}>
                <CardActionArea style={{height: "100%"}} component={RouterLink} to={{
                    pathname: `/products/${jigpaw.linkUUID()}`
                }}>
                    <Grid style={{height: "100%"}}
                          container
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                    >
                        <CircularProgress/>
                    </Grid>
                </CardActionArea>
            </Card>
        </Delayed>
    )
}

export default JigpawCardPlaceHolder
