import React, {useCallback, useState, useEffect} from 'react';
import _ from 'lodash';
import {makeStyles} from '@mui/styles';
import Button from '@mui/material/Button';
import Link from "@mui/material/Link";
import {Link as RouterLink, useHistory, useLocation} from "react-router-dom";

import Container from "@mui/material/Container";

import CartRow from "../components/CartRow";
import {getAuth} from "firebase/auth";
import {app} from "../../firebase";
import {useAuthState} from "react-firebase-hooks/auth";
import {useBasket} from "../../models";
import {useDiscount, useProducts} from "../../API";
import Typography from "@mui/material/Typography";
import Dinero from 'dinero.js';
import {useLocale} from "../../api-locale";
import TextField from "@mui/material/TextField";
import {jigpawAPI} from "../../API";
import IconButton from "@mui/material/IconButton";
import CartTotals2 from '../components/CartTotals2';

const useStyles = makeStyles((theme) => ({
    form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end', // Align items to the right
    maxWidth: 300, // Adjust the width as needed
    margin: 'auto', // Center the form horizontally
  },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

const buttonStyles = {
    padding: "20px 60px",
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0.05em"
}

const CartContainer = ({user, currency}) => {
    const classes = useStyles();
    //TODO: GERRY COME BACK AND IMPLEMENT THESE UNUSUED PROPERTIES
    // const [basket, basketLoading, basketError] = useBasket(user);
    const [basket ] = useBasket(user);
    const [productResponse] = useProducts("all", currency);
    // const [productResponse, productsError] = useProducts("all", currency);
    
    // console.log(JSON.stringify(basket,null,2))

    const hasItems = (basket) ? basket.hasItems() : false;

    const history = useHistory();
    const location = useLocation();

    const [newCode, setNewCode] = useState("");
    const [discountCodeError, setDiscountCodeError] = useState(null);
    const [discount, discountLoading] = useDiscount(user, basket);


    const calculateBasketTotals = (basket, productResponse, currency) => {
        const { subtotal, total } = basket.totalPrice(productResponse.products);
        const subtotalFormatted = Dinero({ amount: subtotal, currency: currency }).toFormat();
        const totalFormatted = Dinero({ amount: total, currency: currency }).toFormat();
        const totalUK = Dinero({ amount: total, currency: "GBP" }).toFormat();
    
        return { subtotalFormatted, totalFormatted, totalUK };
    };



    useEffect(() => {
        if (basket && basket.items && productResponse) {
            const itemsArray = Object.values(basket.items);
            const { subtotalFormatted, totalFormatted, totalUK } = calculateBasketTotals(basket, productResponse, currency);

    
            window.dataLayer.push({
                event: 'cart-viewed',
                page: {
                    url: location.pathname,
                    title: 'shopping-cart'
                },
                basket: {
                    items: itemsArray.map(item => ({
                        itemName: item.product,
                        quantity: item.quantity,
                        itemCategory: item.category,
                        priceCurrency: currency
                    })),
                    subtotal: subtotalFormatted,
                    total: totalFormatted,
                    totalUK
                }
            });
        } else {
            console.error('Invalid basket or product response', basket);
        }
    }, [basket, productResponse, currency]);
    
  

    function continueToShipping() {
        if (basket && basket.items && productResponse) {
            const itemsArray = Object.values(basket.items);
            const { subtotalFormatted, totalFormatted, totalUK } = calculateBasketTotals(basket, productResponse, currency);


            window.dataLayer.push({
                event: 'continue-to-shipping',
                basket: {
                    items: itemsArray.map(item => ({
                        itemName: item.product,
                        quantity: item.quantity,
                        itemCategory: item.category,
                        priceCurrency: currency
                    })),
                    subtotal: subtotalFormatted,
                    totalPrice: totalFormatted,
                    totalUK
                }
            });
            history.push("/shop/billing-and-shipping");
        } else {
            console.error('Invalid basket or product response');
        }
    }
    

    function applyDiscount() {
        // console.log("Apply discount", newCode);
        jigpawAPI.apply_discount(user, newCode).then((data) => {
            // console.log(data);

        }).catch((error) => {
            // console.log("show message error", error.response, error.response.data.message);
            const message = error.response.data.message;
            if (message) {
                setDiscountCodeError(message);
            } else {
                setDiscountCodeError("Unable to apply code, please try again.");
            }

        });
    }

    const removeDiscount = useCallback(() => {
        if (basket) {
            // console.log("Removing discount");
            basket.removeDiscount().then(()=> {
                // console.log("Removed discount", discount)
            })
        }
    }, [basket]);


    if (basket && productResponse && hasItems) {
    // console.log('productResponse:', productResponse);
        const {discounts, subtotal, total} = basket.totalPrice(productResponse.products);

        const subtotalFormatted = Dinero({amount: subtotal, currency: currency}).toFormat();
        const totalFormatted = Dinero({amount: total, currency: currency}).toFormat();
        let discountArea = (null);
        if (!_.isEmpty(discounts)) {
            discountArea = (
                <>
                    {_.map(discounts, (discount) => {
                        const formatted = Dinero({amount: discount.amount, currency: currency}).toFormat()
                        if (discount.type === 'basket') {
                            return (
                                <div className="removeDiscount">
                                    <IconButton aria-label="close" onClick={removeDiscount} size="large"><img alt="remove" className={classes.deleteOverlay} src={"/images/svg/del.svg"} /></IconButton>
                                    <span>{discount.text}</span> <span>{formatted}</span>
                                </div>
                            );
                        }

                        return (
                            <div>
                                <span>{discount.text}</span> <span>{formatted}</span>
                            </div>
                        );
                    })}
                </>
            )
        }

        // sort items, so they remain the same between renders..
        const items = _.sortBy(basket.items, ['jigpawId', 'product'])

        let basketItemsArray = [];

if (basket && basket.items && productResponse) {
    basketItemsArray = Object.entries(basket.items).map(([key, value]) => {
        const product = productResponse.products.find(product => product.id === value.product);
        if (product) {
            const totalPrice = product.price * value.quantity; // Calculate total price
            return {
                id: key,
                product,
                totalPrice, // Include total price in the object
                ...value
            };
        } else {
            console.log(`Product with id ${value.product} not found in productResponse`);
            return null;
        }
    }).filter(Boolean);
} else {
    console.log("Basket is empty or items not found");
}

// console.log('basketItemsArray:', basketItemsArray);

        return <>
            <Container className={`productsHome bgGray`} disableGutters={true} maxWidth={false}>
                <div className="spacer"/>
                <div><h1>Shopping cart</h1></div>
                <div className="spacer"></div>
                <div className="jpDivider bgBlue"/>
            </Container>

            <div className="spacer"/>

            <Container disableGutters={true} maxWidth="md" style={{height: "100%"}}>
                {_.map(items, (item, key) => {
                        const product = _.find(productResponse.products, {'id': item.product});
                        if (product) {
                            return (
                                <CartRow key={key} item={item} basket={basket} user={user} product={product}
                                         currency={currency}></CartRow>);
                        }
                        return (null);
                    }
                )}
            </Container>

            <Container maxWidth="md" disableGutters={true}>

            <CartTotals2 
                 subtotalFormatted={subtotalFormatted}
                 totalFormatted={totalFormatted}
                            discountArea={<div className="discounts">{discountArea}</div>}
            />

            <div className="spacerMed"/>

            <div className="formContainerWhite">
                <div className="formBoxWhite">
                    <TextField
                        id="filled-basic"
                        label="Discount code"
                        value={newCode}
                        // required
                        error={discountCodeError}
                        helperText={discountCodeError}
                        onChange={e => { setDiscountCodeError(null); setNewCode(e.target.value) }}
                        variant="filled"
                        fullWidth                            
                    />
                    
                    <Button 
                        style={{padding: "20px 60px", fontSize: "20px"}} 
                        onClick={applyDiscount} 
                        variant="contained" 
                        size="large" 
                        className={classes.margin}
                    >
                        Apply
                    </Button>
                </div>
            </div>

            <div className="spacerMed"/>

                {user.isAnonymous &&
                    <>
                        <div className="bgWhite">
                            <body>Store your lovely pet photos by creating an account.</body>
                            <div className="spacerMed"/>

                            <div className="checkoutFixedWidth">
                                <Button style={buttonStyles} variant="contained" size="large" className={classes.margin}
                                        component={RouterLink}
                                        to={{
                                            pathname: "/create-account",
                                            state: {redirect: "/shop/billing-and-shipping"}
                                        }}>
                                    Create Account
                                </Button>
                                {/* <Button 
                                    style={buttonStyles}
                                    variant="contained" 
                                    size="large" 
                                    className={`${classes.margin} filled`}
                                    component={RouterLink}
                                    to="/shop/billing-and-shipping">
                                        Continue as guest
                                </Button> */}

                                <Button 
                                    style={buttonStyles}
                                    variant="contained" 
                                    size="large" 
                                    className={`${classes.margin} filled`}
                                    onClick={continueToShipping}
                                >
                                    Continue as guest
                                </Button>
                                
                                </div>
                            <div className="spacerMed"/>
                            <div className="spacerSmall"/>
                            <body>Already have an account?</body>
                            <Link
                                component={RouterLink}
                                to={{
                                    pathname: "/sign-in",
                                    state: {redirect: "/shop/billing-and-shipping"}
                                }}
                                underline="hover">Sign In</Link>
                        </div>
                    </>
                }
                {!user.isAnonymous &&

                    <Button 
                        variant="contained" 
                        size="large" 
                        className={`${classes.margin} filled`}
                        onClick={continueToShipping}
                        style={buttonStyles}
                    >
                        Continue to shipping
                    </Button>
                }
            </Container>
            <div className="spacer"/>
        </>;
    } else if (basket && !hasItems) {
        return <>
            <Container className={`productsHome bgGray`}  disableGutters={true} maxWidth={false}>
                <div className="spacer"/>
                <div><h1>Shopping cart</h1></div>
                <div className="spacer"></div>
                <div className="jpDivider bgBlue"/>


            </Container>
            <Typography variant="body2">
                Your cart is empty, <Link component={RouterLink} to="/create" underline="hover">create</Link> some jigpaws!
            </Typography>
        </>;
    }
    return (null);
}

const CartCurrency = ({user}) => {
    //TODO: GERRY COME BACK AND IMPLEMENT THESE UNUSUED PROPERTIES
    // const {locale, loading, e} = useLocale(user);
    const {locale } = useLocale(user);

    if (locale) {
        return (
            <CartContainer user={user} currency={locale.currencyCode}></CartContainer>
        );
    }
    return (null);
}


export default function Cart() {
    const auth = getAuth(app);
    //TODO: GERRY COME BACK AND IMPLEMENT THESE UNUSUED PROPERTIES
    // const [user, authLoading, authError] = useAuthState(auth);
    const [user]  = useAuthState(auth);

    if (user) {
        return (
            <CartCurrency user={user}></CartCurrency>
        )
    }
    return (null);
}


