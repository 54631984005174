import React from "react"
import { Grid } from "@mui/material"



const CenterGrid = ({children}) => {
    return(

        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{height: '100%'}}>
            {children}
        </Grid>

    )

}

export default CenterGrid;