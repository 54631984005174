// REACT
import React, { useContext } from "react";

// INTERNAL IMPORTS
import { PhotobookStyleContext } from "../../utils";

// EXTERNAL IMPORTS
import { useDraggable } from "muuri-react";

// HOOKS, UTILS, DATA
import { useStyles } from "./useStyles";



const BlankPage = ({isFrontPage, isBackPage}) => {
    const classes = useStyles();
    const {style} = useContext(PhotobookStyleContext);
    const setDraggable = useDraggable();
    setDraggable(false);

    const className = (isBackPage) ? "item-content even" : "item-content odd";
    return (
        <div style={style} className={`item ${classes.mobileFix}`}>
            <div className={className}>Blank Page</div>
        </div>
    );
}

export default BlankPage;