import React from 'react';
import {makeStyles} from '@mui/styles';
import Paper from "@mui/material/Paper";

import {Container} from "@mui/material";

import Button from "@mui/material/Button";


const useStyles = makeStyles((theme) => ({
    container: {
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        background: '#fff',
    },
    wrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        flexGrow: 1,
        width: 1024,
        margin: "auto",
        [theme.breakpoints.down('lg')]: {
            width: "100%",
            margin: "none,"
        },
    },
    header: {
        background: '#fff',
        color: '#413e40',
        textAlign: "left",
        marginLeft: 40,
        marginTop: 40,
        marginBottom: 20,
    },
    section: {
        background: '#fff',
        color: '#413e40',
        textAlign: "left",
        marginLeft: 40,
        marginTop: 20,
        marginBottom: 20,
        marginRight: 40,
    }
}));


export default function FourOhFour() {
    const classes = useStyles();

    return (

        <React.Fragment>
            {/*<Helmet>*/}
            {/*            <title>Jigpaws – Free shipping | Delivery times and FAQs</title>*/}
            {/*            <meta name="description"*/}
            {/*                  content="Free shipping | Delivery times and FAQs"/>*/}
            {/*            <link rel="canonical" href="https://www.jigpaws.com/deliveries-and-returns"/>*/}
            {/*            <meta property="og:url"*/}
            {/*                  content="https://www.jigpaws.com/deliveries-and-returns"/>*/}
            {/*            <meta property="og:type" content="article"/>*/}
            {/*            <meta property="og:title" content="Jigpaws – Free shipping | Delivery times and FAQs"/>*/}
            {/*            <meta property="og:description"*/}
            {/*                  content="Simply upload one of your many pet photos and create a lovingly crafted personalised pet photo gift to share with friends and family around the world."/>*/}
            {/*            <meta property="og:image" content="https://www.jigpaws.com/images/social-logo.png"/>*/}
            {/*        </Helmet>*/}
            <div className={classes.container}>
                <Container className={"productsHome bgGray"} disableGutters={true} maxWidth={false}>
                    <div className="spacer"/>
                    <div><h1>PAGE NOT FOUND</h1></div>
                    <div className="spacer"></div>
                    <div className="jpDivider bgBlue"/>

                </Container>

                <div className={classes.wrapper}>
                    {/*<Paper elevation={0} square={true} className={classes.header}>*/}
                    {/*  <Typography variant={"h3"}>DELIVERIES & RETURNS</Typography>*/}
                    {/*</Paper>*/}

                    <Paper elevation={0} square={true} className={classes.section}>


                    </Paper>
                    <Paper elevation={0} square={true} className={classes.section}>
                        <h4>Something went wrong...</h4>

                        <body>
                            We can't find the page you are looking for.
                        <br/><br/>
                        </body>
                        <Button size="small" variant="outlined" href="/">Jigpaws home</Button>

                    </Paper>
                </div>
            </div>
        </React.Fragment>
);
}
