import React from "react";
import Grid from "@mui/material/Grid";
import CenterGrid from "../CenterGrid.js";
import Button from "@mui/material/Button";
import { StyledButton } from "../styledComponents.js";
import { useHistory } from "react-router-dom";

const SliderItem = ({ title, body, buttonText, buttonLink }) => {

  // console.log({buttonLink})

  const history = useHistory(); // useHistory hook inside the function body

  const handleButtonClick = () => {
    history.push(buttonLink); // Use history.push to navigate
  };

  return (
    <CenterGrid>
      <Grid>
        <div className="spacer" />
        <div>
          <h3>{title}</h3>
        </div>
        {body && (
          <>
            <div className="spacerMed" />
            <div className="body">{body}</div>
          </>
        )}
        <div className="spacerMed" />
        <StyledButton type={"bigwhitealternate"} onClick={handleButtonClick}>
          {buttonText}
        </StyledButton>
        <div className="spacer" />
      </Grid>
    </CenterGrid>
  );
};

export default SliderItem;
