import React from 'react';
import Grid from '@mui/material/Grid';

const CartTotals2 = ({ subtotalFormatted, shippingPriceFormatted, totalWithShippingFormatted, discountArea, slimLine, totalFormatted }) => {
    return (
        <Grid item xs={12}>
        {!slimLine && 
            <>
                <div className="spacer" />
                <div className="spacer borderTop" />
            </>

        }

            <div className="cartTotals">
                <>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                    >

                        <div className="cartDivider" style={{paddingBottom: "12px"}}>
                            <Grid item className="cartSubContainer">
                                <div>Sub-total</div>
                                <div className="cartSub">{subtotalFormatted}</div>
                            </Grid>

                            {shippingPriceFormatted &&
                                <Grid item className="cartSubContainer">
                                    <div>Shipping</div>
                                    <div className="cartSub">{shippingPriceFormatted}</div>
                                </Grid>
                            }
                        </div>
                    </Grid>
                </>

                <div className="spacerSmall"/>

                {discountArea && <div className="discounts">{discountArea}</div> }
                            
                <div>Total</div>
                 {totalWithShippingFormatted && <div className="cartPrice">{totalWithShippingFormatted}</div>}
                 {totalFormatted && <div className="cartPrice">{totalFormatted}</div>}
                
                <div className="spacer "/>
            </div>
        </Grid>
    );
};

export default CartTotals2;
