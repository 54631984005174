import React from "react";
import {useLocation} from "react-router-dom";
import {makeStyles} from "@mui/styles";


// The theme context.
export const PhotobookStyleContext = React.createContext(null);

export const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

export const useFormStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        // padding: theme.spacing(2),
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '100%',
            // width: '300px',
        },
        '& .MuiButtonBase-root': {
            margin: theme.spacing(2),
            width: '100%',
            // width: '300px',
        },
    },
    container: {
        maxWidth: "416px"
    }
}));

export const jigpawsDineroFormatted = (d: Dinero) => {
    if (d.hasSubUnits()) {
        return d.toFormat('$0,0.0');
    }
    return d.toFormat('$0,0');
};

export const useStyles = makeStyles((theme) => ({
    root: {
        height: "10px",
        backgroundColor: "#413e40",
    },
    moveToFront: {
        [theme.breakpoints.down('md')]: {
            order: -1,
        },
    }
}));

