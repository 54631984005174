//REACT
import React, {useEffect, useState} from 'react';
import {Link as RouterLink} from "react-router-dom";

//FIREBASE
import { getAuth } from "firebase/auth";
import { app} from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";

//MATERIALS
import Button from '@mui/material/Button';
import Link from "@mui/material/Link";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from "@mui/material/Typography";
import {makeStyles} from '@mui/styles';

//INTERNAL IMPORTS
import { jigpawAPI, useCountries, useDiscount, useProducts } from "../../API";
import { emptyBasket, useBasket } from "../../models";


//EXTERNAL IMPORTS
import * as yup from 'yup';
import _ from 'lodash';
import Dinero from "dinero.js";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { v4 as uuidv4 } from 'uuid'; 

//COMPONENTS
import CheckoutForm from "../components/CheckoutForm";
import ShippingForm from "../components/ShippingForm";
import ShippingOptions from "../components/ShippingOptions";
import { PageProgress } from "../../global/components/ProgressBars";

//HOOKS AND UTILS
import {useFormStyles} from "../../utils";
import {useLocale} from "../../api-locale";
import CartTotals2 from '../components/CartTotals2';

//TODOS
//GERRY TODO: various unused properties throughout this file

const buttonStyles = {
    padding: "20px 60px",
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0.05em"
}

const buttonStylesSmall = {
    padding: '10px 30px',
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0.05em"
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

// console.log("stripePromise", stripePromise);
let stripe2
(async () => {
    stripe2 = await loadStripe(process.env.REACT_APP_STRIPE_PK);
    // console.log("stripe2", stripe2);
})();

// console.log("REACT_APP_STRIPE_PK", process.env.REACT_APP_STRIPE_PK)

const Checkout = (props) => {

    //todo
    // const {user, basket, products, shippingAddress, shippingMethod, currency, discount} = props;
    const {user, basket, products, shippingAddress, shippingMethod, currency } = props;
    const [clientSecret, setClientSecret] = useState("");
    const [orderId, setOrderId] = useState("")
    const [basketTotal, setBasketTotal] = useState()

    useEffect(() => {

        const idempotencyKey = uuidv4();

        // Create PaymentIntent as soon as the page loads

        const total = basket.totalPrice(products)["total"];
        setBasketTotal(total);

        const expectedAmount = total + shippingMethod.shippingPrice;


        jigpawAPI.create_payment_intent(user, shippingAddress, shippingMethod.shippingMethod, expectedAmount, currency, idempotencyKey)
            .then((data) => {
                // console.log(data);
                setClientSecret(data.secret);
                setOrderId(data.order_id);
            });
    }, []);

    const appearance = {
        theme: 'stripe',
        labels: 'floating',

        variables: {

            borderRadius: '0px',

        },


        rules: {

            '.Input': {
                backgroundColor: '#ffffff',
                boxShadow: 'none',
                border: 0,
            },
        }
    };
    const options = {
        clientSecret,
        appearance,
        fonts: [
            {'cssSrc': '/webFonts/Facundo/font.css'}
        ]
    };

    return (
        <div>
            {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                    <CheckoutForm email={shippingAddress.email} basket={basket} basketTotal={basketTotal} shippingMethod={shippingMethod} currency={currency} orderId={orderId} />
                </Elements>
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    forceHeight: {
        minHeight: "100vh",
    }
}));

export default function ShippingAndBilling() {
    const auth = getAuth(app);
    //TODO
    // const [user, authLoading, authError] = useAuthState(auth);
    const [user] = useAuthState(auth);


    if (user) {
        return (
            <BillingAndShippingInternal user={user}></BillingAndShippingInternal>
        )
    }
    return (null);
}

const BillingAndShippingInternal = ({user}) => {
    //TODO
    // const {locale, loading, e} = useLocale(user);
    const {locale} = useLocale(user);

    if (locale) {
        return (
            <BillingAndShippingCurrency user={user} currency={locale.currencyCode}></BillingAndShippingCurrency>
        )
    }
    return (null);
}


const BillingAndShippingCurrency = ({user, currency}) => {
    const formClasses = useFormStyles();
    const classes = useStyles();

    //TODO
    // const [basket, basketLoading, basketError] = useBasket(user);
    // const [productResponse, productsError] = useProducts("all", currency);
    const [basket ] = useBasket(user);
    const [productResponse ] = useProducts("all", currency);

    const [discount, discountLoading] = useDiscount(user, basket);
    // PRODUCTION STATES
    const [page, setPage] = useState('shipping')
    const [paymentIntent, setPaymentIntent] = useState({});
    // DEV STATES
    // const [page, setPage] = useState('paymentStatus')
    // statuses "succeeded", "processing", "requires_payment_method"...
    // const [paymentIntent, setPaymentIntent] = useState({status: "succeeded"});
    //

    //TODO
    // const [countriesResponse, countriesError] = useCountries();
    const [countriesResponse] = useCountries();

    const countries = (countriesResponse) ? countriesResponse.data : null;
    // console.log("countries", countries);


    const searchParams = new URLSearchParams(window.location.search)
    const clientSecret = searchParams.get("payment_intent_client_secret");
    const orderId = searchParams.get("order_id")
    const fetchPaymentIntent = () => {
        stripe2.retrievePaymentIntent(clientSecret).then(({paymentIntent}) => {
            // console.log("retrievePaymentIntent", paymentIntent);
            setPaymentIntent(paymentIntent)
        });
    }

    useEffect(() => {
        if (!stripe2) {
            console.log("status stripe2")
            return;
        }
        if (!clientSecret) {
            console.log("status clientSecret")
            return;
        }
        console.log("status paymentStatus")
        setPage("paymentStatus");
        fetchPaymentIntent();
    }, [stripe2]);

    useEffect(() => {

        if (paymentIntent && paymentIntent.status && productResponse && basket && basket.items) {
    // Extract items from basket
    const newItems = basket.items;
    const transformedItems = Object.keys(newItems).map((itemId, index) => {
        const item = newItems[itemId];
        // Find corresponding product in productResponse
        const product = productResponse.products.find(product => product.id === item.product);
        if (product) {
            // Extract and rename properties as needed
            return {
                index: index, // Add index starting from 0
                item_id: item.product,
                item_name: product.name,
                item_category: product.category,
                quantity: item.quantity,
                price: parseFloat((product.price / 100).toFixed(2)), // Convert price to pounds
                currency: product.currency,
            };

        }
    });

    // Get total price
    const products = productResponse.products;
    const { total } = basket.totalPrice(products);
    const value = parseFloat((total / 100).toFixed(2));

    // Define currency
    const currency = productResponse.currency; // Assuming currency is available in productResponse

    // Send data to Google Tag Manager data layer
    if (paymentIntent.status === 'succeeded') {
        window.dataLayer.push({
            event: `purchase`,
            transaction_id: orderId,
            value: value,
            currency: currency,
            items: transformedItems.filter(item => item), // Filter out undefined items
        });
    }

    // Empty basket if payment is successful
    if (paymentIntent.status === "succeeded") {
        emptyBasket(user.uid);
    }
}

    }, [paymentIntent, productResponse])


    const [email, setEmail] = useState();
    const [shippingName, setShippingName] = useState();
    const [shippingLine1, setShippingLine1] = useState();
    const [shippingLine2, setShippingLine2] = useState();
    const [shippingCity, setShippingCity] = useState();
    const [shippingState, setShippingState] = useState();
    const [shippingPostalCode, setShippingPostalCode] = useState();
    const [shippingCountry, setShippingCountry] = useState("GB");
    const [shippingPhone, setShippingPhone] = useState();

    const [preferredShippingMethod, setPreferredShippingMethod] = useState()
    const [shippingErrors, setShippingErrors] = useState({});

    const schema = yup.object().shape({
        email: yup.string().email().required("Email is required."),
        shippingName: yup.string().required("Name is required.").min(3),
        shippingLine1: yup.string().required("Address1 is required."),
        shippingLine2: yup.string(),
        shippingCity: yup.string().required("Town/City is required."),
        shippingState: yup.string().required("Region is required."),
        shippingPostalCode: yup.string().required("Postal Code is required."),
        shippingCountry: yup.string().required("Country required."),
        shippingPhone: yup.string().required("Phone required."),
        preferredShippingMethod: yup.object().shape({
            shippingMethod: yup.string().required("Shipping method is required.")
        }),
    });

    const shippingProps = {
        email,
        shippingName,
        shippingLine1,
        shippingLine2,
        shippingCity,
        shippingState,
        shippingPostalCode,
        shippingCountry,
        shippingPhone,
        preferredShippingMethod
    }

    const checkoutClicked = (e) => {
        e.preventDefault();
        schema.validate(shippingProps, {abortEarly: false}).then(
            () => {
                //Passed validation go to checkout!
                setPage('checkout');
            }).catch(
                (error) => {

                    let validationErrors = {}
                    _.forEach(error.inner, (inner) => {
                        validationErrors[inner.path] = inner.message
                    })
                    setShippingErrors(validationErrors)
                })
    }

    if (page === 'paymentStatus') {

        if (paymentIntent.status === "succeeded") {
            return (
                <div>
                    <Container className={`productsHome bgGray`}  disableGutters={true} maxWidth={false}>
                        <div className="spacer"/>
                        <div><h1>Payment successful!</h1></div>
                        <div className="body">
                            {/*Subheading...*/}
                        </div>
                        <div className="spacer"></div>
                        <div className="jpDivider bgBlue"/>
                    </Container>
                                                <div className="spacer" />

                <Container disableGutters={true} className={formClasses.container}>
                        <div className="formBox bgGreen">
                            <div><h3>THANK YOU <br/>FOR YOUR ORDER</h3></div>
                                <div className="spacerMed"/>
                            <div className="body">Your receipt and shipping confirmation will be emailed to you shortly.
                                Thanks for sharing a little piece of the family.</div>

                            {/*<AnonymousUpgrade initialEmail={email} initialName={shippingName}></AnonymousUpgrade>*/}
                            {user.isAnonymous && <>
                                                            <div className="spacerMed" />

                            <div className="body">Store your lovely pet photos by creating an account.</div>
                             <div className="spacerMed" />

                            <Button style={buttonStyles} size="large" variant="contained" href="/create-account">CREATE account</Button></>}

                            {!user.isAnonymous && <>
                             <div className="spacerMed" />
                            <Button style={buttonStyles} size="large" variant="contained" href="/create-personalised-pet-photo-gifts">CREATE again</Button></>}
                        </div>
                    </Container>


                    <div style={{"min-height": "50vh"}}></div>
                </div>
            )
        }
        if (paymentIntent.status === "processing") {

            return (
                <div>
                    <Container className={`productsHome bgGray`} disableGutters={true} maxWidth={false}>
                        <div className="spacer"/>
                        <div><h1>Your payment is processing</h1></div>
                        <div className="spacer"></div>
                        <div className="jpDivider bgBlue"/>
                    </Container>

                    <Container maxWidth="sm" disableGutters={true}>
                        <div className="formBox">
                            <div className="formName">THANK YOU FOR YOUR ORDER</div>
                                <div className="spacer"/>
                            <div className="body">Your receipt and shipping confirmation will be emailed to you shortly.
                                Thanks for sharing a little piece of the family.</div>
                            <Button style={buttonStylesSmall} size="small" variant="outlined" href="/create-personalised-pet-photo-gifts">CREATE Again</Button>
                        </div>
                    </Container>

                    <div style={{"min-height": "50vh"}}></div>
                </div>
            )
        }
        if (paymentIntent.status === "requires_payment_method") {
            return (
                <div>
                    <Container className={`productsHome bgGray`}  disableGutters={true} maxWidth={false}>
                        <div className="spacer"/>
                        <div><h1>Your payment was not successful</h1></div>
                        <div className="body">Please <Link onClick={e => setPage('shipping')} underline="hover">try again.</Link>

                        </div>
                        <div className="spacer"></div>
                        <div className="jpDivider bgBlue"/>
                    </Container>
                    <div style={{"min-height": "50vh"}}></div>
                </div>
            );
        }
        return <PageProgress/>
    }

    if (!basket || discountLoading) {
        return (<div>loading...</div>)
    }

    if (!basket.hasItems()) {
        //TODO refactor into component and share with Cart.js
        return <>
        <div className="spacer"/>
        <Typography>
            Your cart is empty, <Link component={RouterLink} to="/create" underline="hover">create</Link> some jigpaws!
        </Typography>
            <div className="spacer"/>
            </>;
    }

    if (page === 'shipping' && productResponse) {
        const shippingFormProps = {
            email, setEmail,
            shippingName, setShippingName,
            shippingLine1, setShippingLine1,
            shippingLine2, setShippingLine2,
            shippingCity, setShippingCity,
            shippingState, setShippingState,
            shippingPostalCode, setShippingPostalCode,
            shippingCountry, setShippingCountry,
            shippingPhone, setShippingPhone,
            countries,
            shippingErrors, setShippingErrors,
        }

        // const currency = 'GBP'

        const shippingOptionsProps = {
            user,
            shippingCountry,
            currency,
            preferredShippingMethod,
            setPreferredShippingMethod,
            shippingErrors,
            setShippingErrors,
        }

        const {total, subtotal, discounts} = basket.totalPrice(productResponse.products, discount);
        const shippingPrice = _.get(preferredShippingMethod, 'shippingPrice', null);
        const totalWithShipping = total + shippingPrice

        const subtotalFormatted = Dinero({amount: subtotal, currency: currency}).toFormat();

        let shippingPriceFormatted = ""
        if (shippingPrice === null) {
            shippingPriceFormatted = "N/A"
        } else if (shippingPrice === 0) {
            shippingPriceFormatted = "FREE"
        } else {
            shippingPriceFormatted = Dinero({amount: shippingPrice, currency: currency}).toFormat();
        }

        const totalWithShippingFormatted = Dinero({amount: totalWithShipping, currency: currency}).toFormat();


        let discountArea = (null);
        if (!_.isEmpty(discounts)) {
            discountArea = (
                <>
                    {_.map(discounts, (discount) => {
                        const formatted = Dinero({amount: discount.amount, currency: currency}).toFormat()
                        return (
                            <div><span>{discount.text}</span> <span>{formatted}</span></div>
                        );
                    })}
                </>
            )
        }

        return (

            <div className={classes.forceHeight}>
                <Container className={`productsHome bgGray`}  disableGutters={true} maxWidth={false}>
                    <div className="spacer"/>
                    <div><h1>Shipping</h1></div>
                    <div className="spacer"></div>
                    <div className="jpDivider bgBlue"/>


                </Container>
                <div className="spacer"></div>

                <Container maxWidth="md" style={{height: "100%"}} disableGutters={true}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <div className="formBox">
                                <div className="formName">Shipping Address</div>
                                <div className="spacerMed"/>
                                <ShippingForm {...shippingFormProps}></ShippingForm>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div className="formBox" style={{minHeight: "50%"}}>
                                <div className="formName">Shipping Method</div>
                                <div className="spacerMed"/>
                                <ShippingOptions {...shippingOptionsProps}></ShippingOptions>
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container>                            
                        <CartTotals2
                            subtotalFormatted={subtotalFormatted}
                            shippingPriceFormatted={shippingPriceFormatted}
                            totalWithShippingFormatted={totalWithShippingFormatted}
                            discountArea={<div className="discounts">{discountArea}</div>}
                        />

                        <Grid item xs={12}>
                            <div className="spacer"/>
                            <Button 
                            variant="contained" 
                            size="large" 
                            style={buttonStyles}
                            className={classes.margin}
                            onClick={checkoutClicked}
                            >
                                Checkout
                            </Button>

                            <div className="spacerMed"/>
                            <div className="spacer"/>                            
                        </Grid>

                    </Grid>
                </Container>
            </div>
        );
    }

    if (page === 'checkout' && shippingCountry && basket && productResponse && preferredShippingMethod && !discountLoading) {

        const {total, subtotal, discounts} = basket.totalPrice(productResponse.products, discount);
        const shippingPrice = _.get(preferredShippingMethod, 'shippingPrice', null);
        const totalWithShipping = total + shippingPrice

        const subtotalFormatted = Dinero({amount: subtotal, currency: currency}).toFormat();

        let shippingPriceFormatted = ""
        if (shippingPrice === null) {
            shippingPriceFormatted = "N/A"
        } else if (shippingPrice === 0) {
            shippingPriceFormatted = "FREE"
        } else {
            shippingPriceFormatted = Dinero({amount: shippingPrice, currency: currency}).toFormat();
        }
        
        const totalWithShippingFormatted = Dinero({amount: totalWithShipping, currency: currency}).toFormat();

        let discountArea = (null);
        if (!_.isEmpty(discounts)) {
            discountArea = (
                <>
                    {_.map(discounts, (discount) => {
                        const formatted = Dinero({amount: discount.amount, currency: currency}).toFormat()
                        return (
                            <div><span>{discount.text}</span> <span>{formatted}</span></div>
                        );
                    })}
                </>
            )
        }

        return (
            <div>
                <Container className={`productsHome bgGray`} disableGutters={true} maxWidth={false}>
                    <div className="spacer"/>
                    <div><h1>Billing</h1></div>
                    <div className="Checkout"></div>
                    <div className="spacer"/>

                    <div className="jpDivider bgBlue"/>


                </Container>
                <div className="spacer"/>



                <Container maxWidth={"sm"} disableGutters={true}>

                <CartTotals2
                        subtotalFormatted={subtotalFormatted}
                        shippingPriceFormatted={shippingPriceFormatted}
                        totalWithShippingFormatted={totalWithShippingFormatted}
                        discountArea={<div className="discounts">{discountArea}</div>}
                        slimLine
                    />
                

                        <Checkout user={user}
                                  basket={basket}
                                  products={productResponse.products}
                                  shippingAddress={{
                                      email: email,
                                      name: shippingName,
                                      line1: shippingLine1,
                                      line2: shippingLine2,
                                      city: shippingCity,
                                      state: shippingState,
                                      postal_code: shippingPostalCode,
                                      country: shippingCountry,
                                      phone: shippingPhone,
                                  }}
                                  shippingMethod={preferredShippingMethod}
                                  currency={currency}
                                  discount={discount}
                        >
                        </Checkout>
                    <div className="spacer"/>
                    <div className="smallButtonOverride">
                    <Button variant="text" size="small" className={classes.margin}
                            onClick={e => setPage('shipping')}>Back</Button>

                    </div>
                </Container>

            </div>

        )
    }
    return (
        <PageProgress />
    );
};



