import React, { useEffect, useState } from "react";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import Dinero from "dinero.js";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
    pay: {
        marginTop: theme.spacing(2),
    },
}));

const buttonStyles = {
    padding: "20px 60px",
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0.05em"
}

export default function CheckoutForm({ email, basket, basketTotal, shippingMethod, currency, orderId }) {
    const stripe = useStripe();
    const elements = useElements();
    const classes = useStyles();

    // console.log({orderId})

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const basketTotalFormatted = Dinero({ amount: basketTotal, currency: currency }).toFormat();
    const basketTotalUK = Dinero({ amount: basketTotal, currency: "GBP" }).toFormat();


    const returnUrl = new URL(window.location.href);
    returnUrl.searchParams.set('order_id', orderId);
    // console.log("Return URL set to:", returnUrl.toString());


    const handleSubmit = async (e) => {

        e.preventDefault();
        // console.log("Form submitted");

        if (!stripe || !elements) {
            return;
        }

        setIsLoading(true);
        // console.log("Loading state set to true");

        window.dataLayer.push({
            event: 'payment-attempt',
            basket: {
                id: basket.uuid,
                createdAt: basket.createdAt,
                items: basket.items,
                basketTotal: basketTotalFormatted,
                basketTotalUK,
            },
            error: undefined,
            orderId,
            shippingMethod
        });


        // console.log("Pushed payment-attempt event to dataLayer");

        // const returnUrl = new URL(window.location.href);
        // returnUrl.searchParams.set('order_id', orderId);
        // console.log("Return URL set to:", returnUrl.toString());

        try {
            const { error, paymentIntent } = await stripe.confirmPayment({
                elements,
                confirmParams: {
                    return_url: returnUrl.toString(),
                    payment_method_data: {
                        billing_details: {
                            email: email,
                        }

                    }
                },
            });

            if (error) {
                const deIdentifiableError = _.pick(error, [
                    'type',
                    'charge',
                    'code',
                    'decline_code',
                    'message',
                    'payment_intent.id'
                ]);
                // console.log("Payment error occurred:", deIdentifiableError);

                if (error.type === "card_error" || error.type === "validation_error") {
                    setMessage(error.message);
                    // console.log("Card or validation error message set:", error.message);

                    window.dataLayer.push({
                        event: 'payment-error',
                        error: deIdentifiableError,
                        basket: {
                            id: basket.uuid,
                            createdAt: basket.createdAt,
                            items: basket.items,
                            basketTotal: basketTotalFormatted,
                            basketTotalUK,
                        },
                        orderId,
                        shippingMethod
                    });
                    // console.log("Pushed payment-error event to dataLayer");
                } else {
                    setMessage("An unexpected error occurred.");
                    // console.log("Unexpected error message set");

                    window.dataLayer.push({
                        event: 'payment-unknown-error',
                        error: deIdentifiableError,
                        basket: {
                            id: basket.uuid,
                            createdAt: basket.createdAt,
                            items: basket.items,
                            basketTotal,
                            basketTotalUK,
                        },
                        orderId,
                        shippingMethod
                    });
                    // console.log("Pushed payment-unknown-error event to dataLayer");
                }

                setIsLoading(false);
                // console.log("Loading state set to false due to error");
            } else if (paymentIntent && paymentIntent.status === 'succeeded') {
                // console.log("Payment succeeded, paymentIntent:", paymentIntent);

                localStorage.setItem('paymentCompleted', 'true');
                // console.log("Set paymentCompleted flag in localStorage");

                window.location.href = returnUrl.toString();
                // console.log("Redirecting to:", returnUrl.toString());
            }
        } catch (err) {
            // console.error("Exception occurred during payment confirmation:", err);
            setIsLoading(false);
            // console.log("Loading state set to false due to exception");
        }
    };

    useEffect(() => {
        // console.log("useEffect called");

        const paymentCompleted = localStorage.getItem('paymentCompleted');
        // console.log("paymentCompleted flag from localStorage:", paymentCompleted);

        if (paymentCompleted === 'true') {
            // console.log("Payment already completed, redirecting...");

            const returnUrl = new URL(window.location.href);
            returnUrl.searchParams.set('order_id', orderId);
            window.location.href = returnUrl.toString();
            // console.log("Redirecting to:", returnUrl.toString());
        }
    }, [orderId]);

    if (!stripe || !elements) {

        // console.log("Stripe or elements not loaded");
        return null;

    }

    return (
        <div className={"formBox"}>
            <form id="payment-form" onSubmit={handleSubmit}>
                <PaymentElement id="payment-element" />
                <Button style={buttonStyles} disabled={isLoading} size="large" variant="contained" type="submit" className={classes.pay}>PAY NOW</Button>
                {message && <div className="payment-message" id="payment-message">{message}</div>}
            </form>
        </div>

    );
}
