import React from 'react';
import {makeStyles} from '@mui/styles';
import Button from '@mui/material/Button';
import Grid from "@mui/material/Grid";
import {FormControl, MenuItem, Select} from "@mui/material";
import {app } from "../../firebase";
import Dinero from 'dinero.js';
import _ from "lodash";
import {useProductPreviewImage} from "../../storageUtils";
import {Square} from "../../global/components/Square";
import {useHistory} from "react-router-dom";
import {doc, getFirestore} from "firebase/firestore";
import {jigpawConverter} from "../../models";
import {useDocumentData} from "react-firebase-hooks/firestore";


const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    menuPaper: {
        maxHeight: "25vh",
    }
}));


export default function CartRow({item, basket, user, product, currency}) {
    const classes = useStyles();
    const history = useHistory();
    const db = getFirestore(app);
    const jigpawId = (item.oid === "system") ? `${user.uid}_${item.jigpawId}` : item.jigpawId;

    const ref = doc(db, "jigpaws", jigpawId).withConverter(jigpawConverter);

    //todo
    // const [jigpaw, jigpawLoading, jigpawError] = useDocumentData(
        const [jigpaw] = useDocumentData(
        ref,
        {
            snapshotListenOptions: {includeMetadataChanges: true},
        }
    );



    const {url} = useProductPreviewImage(user, item.oid, item.jigpawId, product.preview);

    const updateQuantity = (quantity) => {
        if (item.category === "photobook") {
            basket.updatePhotobookQuantity(quantity);
        } else {
            basket.updateItem(item.jigpawId, item.product, quantity);
        }
    };


    const removeFromCart = () => {
        if (item.category === "photobook") {
            basket.removePhotobook();
        } else {
            basket.removeItem(item.jigpawId, product.id);
        }
        window.dataLayer.push({
            'event': 'remove-from-cart',
            'item': {
                // jigpawId: item.jigpawId,
                // ownerId: item.oid,
                // product: product.id,
                // category: product.category,
            }
        })
    };

    const navigateToProduct = () => {
        if (jigpaw) {

            const pathname = `/product/${product.category.replace("_", "-")}/${jigpaw.linkUUID()}`;
            history.push({
                pathname: pathname,
                state: {
                    variantId: product.id
                }
            })
        } else {
            console.log("gpot here :(")
        }
    }

    const formattedPrice = Dinero({amount: product.price, currency: currency}).toFormat();
    console.log('formattedPrice:', formattedPrice);
    if (url && jigpaw) {
        return (
            <>
                {/* <Grid container spacing={0} alignItems="center" alignItems="stretch" direction={"row"} */}
                {/* GERRY CHANGES */}
                <Grid container spacing={0} alignItems="stretch" direction={"row"}
                      className="cartItemContainer">
                    <Grid item xs={12} md={3} className="cartItem">
                        <Square>
                            <img alt="navigate to product" onClick={navigateToProduct} src={url} className={"jigpaw"} style={{"cursor": "pointer"}}/>
                        </Square>
                    </Grid>
                    {/**/}
                    <Grid item xs={12} md={9} className="cartContent">
                        <div className="cartItemRightContainer">
                            <Grid container style={{height: "100%"}}>
                                <Grid item xs={9} sm={9} md={6} alignItems="left" style={{"textAlign": "left"}}>
                                    <div className="cartInfo">
                                        <div className="cartInfoTop">
                                            <div>
                                                {item.displayCategory()}
                                            </div>
                                        </div>
                                        <div className="spacerSmall"/>
                                        <div className="detail">
                                            {product.displayVariant}
                                        </div>

                                        <div className="cartInfoBottom" style={{"textAlign": "left"}}>
                                            <div>
                                                <FormControl className="inPageDrop">
                                                    <Select
                                                        className="inPageDrop"
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={item.quantity}
                                                        label="Quantity"
                                                        onChange={e => updateQuantity(e.target.value)}
                                                        MenuProps={{ classes: { paper: classes.menuPaper } }}
                                                    >
                                                        {_.times(100, (num) => {
                                                            num += 1
                                                            return (
                                                                <MenuItem value={num}>{num}</MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div className="spacerSmall"/>

                                            <div>
                                                <Button variant="text" size="small" onClick={removeFromCart}>Remove from
                                                    cart</Button></div>
                                            {/*<div className="formLink"><Link><Button>Forgotten password?</Button></Link></div>*/}

                                            <div className="spacerSmall"/>


                                        </div>

                                    </div>
                                </Grid>
                                <Grid item xs={3} sm={3} md={6} alignItems="right" className="cartPriceContainer">
                                    <div className="cartPrice">
                                        {formattedPrice}
                                    </div>
                                </Grid>
                            </Grid>
                        </div>

                    </Grid>

                </Grid>
                                        <div className="cartDivider desktopNone"/>

                <div className="spacer"/>
                <div style={{"clear":"both"}}></div>
            </>
        )
    } else {
        return (null);
    }
}


