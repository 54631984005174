import React from 'react';
import { CustomButton } from "../../styledComponents";

const Modal = ({ open, onClose, message }) => {
    if (!open) return null;

    return (
        <div style={{
            position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
            backgroundColor: 'white', padding: '20px', zIndex: 1000,
            border: '1px solid #ccc', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            display: 'flex', flexDirection: 'column', alignItems: 'center',
            maxWidth: '90%', // Ensures the modal is responsive
        }}>
            <p style={{
                fontSize: '24px', // Larger text size for better readability
                fontWeight: '400',
                marginBottom: '20px', // Adds space between the text and the button
                textAlign: 'center', // Centers the text if it's multiple lines
                color: '#333', // Slightly darker text for better contrast
                width: '100%', // Ensures text alignment is controlled by container
            }}>
                {message}
            </p>
            <CustomButton onClick={onClose}>Close</CustomButton>
        </div>
    );
};

export default Modal;
