import React from 'react';
import {makeStyles} from '@mui/styles';
// Import the editor styles
import 'pintura/pintura.css';
import '../static/image-editor.css';
// Import the editor default configuration
import {
    // Import the default image reader and writer
    createDefaultImageReader,
    createDefaultImageWriter,

    // The method used to register the plugins
    setPlugins,

    // The plugins we want to use
    plugin_crop,
    plugin_filter,
    plugin_annotate,
    plugin_sticker,

    // The user interface and plugin locale objects
    locale_en_gb,
    plugin_crop_locale_en_gb,
    plugin_filter_locale_en_gb,
    plugin_annotate_locale_en_gb,
    plugin_sticker_locale_en_gb,

    // Because we use the annotate plugin we also need
    // to import the markup editor locale and the shape preprocessor

    markup_editor_locale_en_gb,
    createDefaultShapePreprocessor,

    // Import the default configuration for the markup editor and finetune plugins
    markup_editor_defaults,
    plugin_finetune_defaults,
    plugin_filter_defaults,
} from 'pintura';
// Import the editor component from `react-pintura`
import {PinturaEditor } from 'react-pintura';
import {useHistory } from "react-router-dom";
import {ref} from "firebase/storage";
import {anonymousBucket, jigpawBucket } from "../../firebase";
import _ from "lodash";
import {v4 as uuidv4} from "uuid";
import {createJigpaw} from "../../models";
import {uploadBytesResumable } from "firebase/storage";
import {useOriginalImage} from "../../storageUtils";


const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

const createJigpawWithFile = (user, file, filename) => {

    const uuid = uuidv4();
    const bucket = (user.isAnonymous) ? anonymousBucket : jigpawBucket;
    const path = `${user.uid}/${uuid}/${filename}`;
    const storageRef = ref(bucket, path);


    // Create the file metadata
    /** @type {any} */
    const metadata = {
      contentType: 'image/jpeg'
    };
    return {uploadTask: uploadBytesResumable(storageRef, file, metadata), uuid: uuid};
};

const isGreetingCard = (product) => {
    return (_.get(product, "category") === "greeting_card");
};

const isJournal = (product) => {
    return (_.get(product, "category") === "journal");
};

const isJigsaw = (product) => {
    const productId = _.get(product, "id");
    return _.includes(["jigsaw-500-50x38", "jigsaw-1000-66x50"], productId);
};

const isPhotobookFrontPage = (product) => {
    return (_.get(product, "category") === "photobookFrontPage");
};

const filename = (product) => {
    if (isJournal(product)) {
        return "journal.jpg";
    }
    if (isJigsaw(product)) {
        return "jigsaw.jpg";
    }
    return "web.jpg";
};


const productConfig  = (product) => {
    if (isGreetingCard(product)) {
        return {
            stickers: [
                [
                    'Emojis',
                    ['😀', '😃', '😄', '😁', '😆', '😅', '🤣', '😂', '🙂', '🙃', '😊', '😇', '🥰', '😍', '🤩', '😘', '😗',
                        // '☺',
                        '😚', '😙', '🥲', '😜', '🤪', '🥳', '😎'],
                    {
                        // icon: '<g><!-- SVG here --></g>',
                        // hideLabel: true,
                    },
                ],
                [
                    'Animals',
                    ['😺', '😸', '😹', '😻', '😼', '😽', '🙀', '😿', '😾', '🙈', '🙉', '🙊', '🐵', '🐶', '🐕', '🦮', '🐕‍🦺', '🐩', '🐺', '🦊', '🐱', '🐈', '🐈‍⬛', '🦁', '🐯'],
                    {
                        // icon: '<g><!-- SVG here --></g>',
                        // hideLabel: true,
                    },
                ],
                [
                    'Seasonal',
                    ['🌝', '⭐', '🌈',
                        // '❄', '☃',
                        '⛄', '👼', '🎅', '🤶', '🧑‍🎄', '🎃', '🎄', '🎉', '🎀', '🎁', '💘', '💝', '💖', '💕',
                        // '❤'
                    ],
                    {
                        // icon: '<g><!-- SVG here --></g>',
                        // hideLabel: true,
                    },
                ],
            ],
            imageCropAspectRatio: 1,
            // annotateTools: ['text'],
            // annotateActiveTool: 'text',
        };
    }
    if (isJigsaw(product)) {
        return {
            imageCropAspectRatio: 7862/5976,//4523/3425,
        };
    }
    if (isJournal(product)) {
        return {
            // cropEnableButtonToggleCropLimit: true,
            imageCropAspectRatio: Math.round((1943 / 2622) * 100) / 100,
            willRenderCanvas: (shapes, state) => {
                const {utilVisibility, selectionRect, backgroundColor } =
                    state;

                // exit if crop utils is not visible
                if (utilVisibility.crop <= 0) {
                    return shapes;
                }

                // shortcuts to selection rect
                const {x, y, width, height} = selectionRect;


                console.log("selectionRect", selectionRect);
                console.log("x", Math.round((x + width * 0.08)), "y", Math.round(y + height * 0.08), "width", Math.round(width * 0.84), "height", Math.round(height * 0.84));

                // return updated shapes
                return {
                    // copy props from shapes param
                    ...shapes,

                    interfaceShapes: [
                        {
                            x: x,
                            y: y,
                            width: width,
                            height: height,
                            text: 'Position inside inner box',
                            fontWeight: 'bold',
                            color: [1, 0, 0],
                        },
                        {
                            x: Math.round((x + width * 0.08)),
                            y: Math.round(y + height * 0.08),
                            width: Math.round(width * 0.84),
                            height: Math.round(height * 0.84),
                            // x: x + width * 0.5,
                            // y: y + height * 0.5,
                            // rx: width * 0.5,
                            // ry: height * 0.5,
                            opacity: utilVisibility.crop,
                            inverted: true,
                            backgroundColor: [...backgroundColor, 0.5],
                            strokeWidth: 1,
                            strokeColor: [255/255, 255/255, 255/255],
                        },
                        ...shapes.interfaceShapes,
                    ],
                };
            },
            // cropWillRenderImageSelectionGuides: (interaction, interactionFraction) => {
            //     const isRotating = interaction === 'rotate';
            //     console.log("interaction", interaction, "interactionFraction", interactionFraction, "interactionFraction * 0.25", interactionFraction * 0.25)
            //     return {
            //         rows: 3,//isRotating ? 5 : 3,
            //         cols: 3, //isRotating ? 5 : 3,
            //         opacity: interactionFraction * 0.25,
            //     };
            // },
        };
    }
    if (isPhotobookFrontPage(product)) {
        return {
            // cropEnableButtonToggleCropLimit: true,
            imageCropAspectRatio: 1,
            willRenderCanvas: (shapes, state) => {
                //todo
                // const {utilVisibility, selectionRect, backgroundColor, lineColor} =
                const {utilVisibility, selectionRect, backgroundColor} =
                    state;

                // exit if crop utils is not visible
                if (utilVisibility.crop <= 0) {
                    return shapes;
                }

                // shortcuts to selection rect
                const {x, y, width, height} = selectionRect;

                const bleed = 0.14;
                return {
                    ...shapes,

                    interfaceShapes: [
                        {
                            x: x,
                            y: y,
                            width: width,
                            height: height,
                            text: 'Position inside inner box',
                            fontWeight: 'bold',
                            color: [1, 0, 0],
                        },
                        {
                            x: Math.round((x + width * bleed)),
                            y: Math.round(y + height * bleed),
                            width: Math.round(width * (1 - (bleed * 2))),
                            height: Math.round(height * (1 - (bleed * 2))),
                            opacity: utilVisibility.crop,
                            inverted: true,
                            backgroundColor: [...backgroundColor, 0.5],
                            strokeWidth: 1,
                            strokeColor: [255/255, 255/255, 255/255],
                        },
                        ...shapes.interfaceShapes,
                    ],
                };
            },
            // cropWillRenderImageSelectionGuides: (interaction, interactionFraction) => {
            //     const isRotating = interaction === 'rotate';
            //     console.log("interaction", interaction, "interactionFraction", interactionFraction, "interactionFraction * 0.25", interactionFraction * 0.25)
            //     return {
            //         rows: 3,//isRotating ? 5 : 3,
            //         cols: 3, //isRotating ? 5 : 3,
            //         opacity: interactionFraction * 0.25,
            //     };
            // },
        };
    }
    return {
        imageCropAspectRatio: 1
    };
};


function ImageEditor(props) {
    //todo
    // const { user, product, jigpaw, jigpawCreated, url } = props;
    // const classes = useStyles();
    const { user, product, jigpawCreated, url } = props;


    const history = useHistory();

    if (isGreetingCard(product)) {
        setPlugins(
            plugin_crop,
            plugin_filter,
            plugin_annotate,
            plugin_sticker
        );
    } else {
        setPlugins(plugin_crop, plugin_filter);
    }

     const locale = {
        ...locale_en_gb,
        ...plugin_crop_locale_en_gb,
        ...plugin_filter_locale_en_gb,
        ...markup_editor_locale_en_gb,
        ...plugin_annotate_locale_en_gb,
        ...plugin_sticker_locale_en_gb,
    };

    locale.labelButtonExport = 'Save';

    //const editorConfig = getEditorDefaults();
    const editorConfig = {
        locale:locale,
        // This will read the image data (required)
        imageReader: createDefaultImageReader(),

        // This will write the output image
        imageWriter: createDefaultImageWriter(
            {
                mimeType: 'image/jpeg', //convert to jpeg
                quality: 90, // quality 90..
            }
        ),
        ...plugin_filter_defaults,
        // The markup editor default options, tools, shape style controls
        ...markup_editor_defaults,

        // The finetune util controls
        ...plugin_finetune_defaults,

        // This handles complex shapes like arrows / frames
        shapePreprocessor: createDefaultShapePreprocessor(),

        ...productConfig(product),

        // cropSelectPresetOptions: [
        //     // [undefined, 'Custom'],
        //     [1, 'Square'],
        //     [1943/2622, 'Journal'],
        //     [4 / 3, 'Jigsaw'], //Not correct...
        // ],


        enableButtonExport: true,
        enableButtonClose: true,

    };

    // const componentRef = useRef(null);
    //
    // useEffect(() => {
    //     // Update the document title using the browser API
    //     const {editor} = componentRef.current;
    //     editor.on('process', console.log);
    // }, []);

    const handleEvent = (type, detail) => {
        // console.log(type, detail);
        if (type === "close") {
            history.goBack();
        } else if (type === "process") {
            const {uploadTask, uuid} = createJigpawWithFile(user, detail.dest, filename(product));
            uploadTask.on('state_changed',
                (snapshot) => {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log('Upload is ' + progress + '% done');
                    switch (snapshot.state) {
                        case 'paused':
                            console.log('Upload is paused');
                            break;
                        case 'running':
                            console.log('Upload is running');
                            break;
                    }
                },
                (error) => {
                    // A full list of error codes is available at
                    // https://firebase.google.com/docs/storage/web/handle-errors
                    switch (error.code) {
                        case 'storage/unauthorized':
                            // User doesn't have permission to access the object
                            break;
                        case 'storage/canceled':
                            // User canceled the upload
                            break;

                        // ...

                        case 'storage/unknown':
                            // Unknown error occurred, inspect error.serverResponse
                            break;
                    }
                },
                () => {
                    // Upload completed successfully, now we can get the download URL
                    // do something else for photobooks...
                    createJigpaw(user.uid, uuid).then((jigpaw) => {
                        jigpawCreated(jigpaw);
                    })
                }
            );
        }
    }

    return (
            <PinturaEditor
                {...editorConfig}
                src={url}
                // ref={componentRef}
                handleEvent={handleEvent}
            ></PinturaEditor>
    )
}

const ImageEditorComponent = ({ user, product, jigpaw, jigpawCreated }) => {

    //make a hook for useOriginalImage..
    // const bucket = (user.isAnonymous) ? anonymousBucket : publicBucket;
    // let path = `${user.uid}/${jigpaw.uuid}/${jigpaw.original}`;
    // if (!user.isAnonymous) {
    //     path = 'jigpaws/' + path;
    // }
    // const [url, urlLoading, urlError] = useDownloadURL(
    //     ref(bucket, path)
    // );

    const {url} = useOriginalImage(user, jigpaw)

    if (url) {
        return (
            <ImageEditor user={user} product={product} jigpaw={jigpaw} jigpawCreated={jigpawCreated} url={url}></ImageEditor>
        );
    }
    return (
        null
    );
}

export default ImageEditorComponent


