import { makeStyles } from '@mui/styles';




export const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1
    },
    drawer: {
      top: 64,
    },
    mobileItems: {
      backgroundColor: '#85a2ad',
      color: '#fff',
    },
    grow: {
      flexGrow: 1,
    },
    mobileMenuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        display: 'none !important',
      },
    },
    toolbarSecondary: {
      color: '#fff',
      backgroundColor: '#85a2ad',
      justifyContent: 'center',
    },
    toolbarLink: {
      flexShrink: 0,
      "&:hover": {
        color: "#99bfc2",
      },
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block',
      },
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    menuIcons: {
      display: 'flex',
    },
    toolbarOffset: theme.mixins.toolbar,
  }));