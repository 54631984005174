import {Dialog, DialogContent, DialogTitle, IconButton, useMediaQuery, useTheme} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {makeStyles} from "@mui/styles";

import PropTypes from "prop-types";
import React from "react";

import ImageEditorComponent from "./ImageEditorComponent";


const useStyles = makeStyles((theme) => ({
    root: {
        // width: 100%,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    paper: {
        height: "70%",
    },
    content: {
        paddingBottom: "20px",
        height: "80vh",
    }
}));

export const ImageEditorModal = (props) => {
    const classes = useStyles();
    const {onClose, open, user, product, jigpaw, jigpawCreated} = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md')); //When to go full screen

    const handleClose = () => {
        onClose();
    };

    const jigpawCreatedWrapper = (value) => {
        jigpawCreated(value);
        onClose();
    };

    return (
        <Dialog fullWidth={true} maxWidth={"lg"} fullScreen={fullScreen}
                onClose={handleClose} classes={classes.paper} open={open}>
            <DialogTitle className="pickerTitle" id="simple-dialog-title">
                <typography variant="h2">Select image</typography>
                <IconButton
                    aria-label="close"
                    className={`${classes.closeButton} jpIcon`}
                    onClick={handleClose}
                    size="large">
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.content}>
                <ImageEditorComponent user={user} product={product} jigpaw={jigpaw} jigpawCreated={jigpawCreatedWrapper}></ImageEditorComponent>
            </DialogContent>
        </Dialog>
    );
}

ImageEditorModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    user: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired,
    jigpaw:  PropTypes.object.isRequired,
    jigpawCreated: PropTypes.func.isRequired,
};