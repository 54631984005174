/* React */
import React from "react";
import Uppy from '@uppy/core';
import Compressor from '@uppy/compressor';
import {DragDrop, useUppy} from '@uppy/react';
// import app from "./firebase";
import '@uppy/core/dist/style.css';
import '@uppy/drag-drop/dist/style.css';
import {uploadRef, app} from "../../firebase";
import {useCollectionData,} from 'react-firebase-hooks/firestore';
import {createJigpaw, jigpawConverter } from "../../models";
import {getFirestore, collection, query, where, orderBy, limit} from "firebase/firestore";
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';

import _ from "lodash";
import {
    Dialog,
    DialogTitle,
    useTheme,
    useMediaQuery,
    Container, IconButton, DialogContent
} from "@mui/material";
import PropTypes from 'prop-types';
import {Square} from "./Square";

import {useMainImage} from "../../storageUtils";
import FirebaseCloudStorage from "../../FirebaseCloudStorage";
import RetryImage from "./RetryImage";


const useStyles = makeStyles((theme) => ({
    root: {
          display: "grid",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
    },
}));

const JigpawSquare = (props) => {
    const {user, jigpaw, imageClicked} = props;
    const classes = useStyles();

    const oid = _.get(jigpaw, "oid", jigpaw.uid);
    const {url} = useMainImage(user, oid, jigpaw.uuid, jigpaw);

    const handleClick = () => {
        // console.log("handleClick", jigpaw);
        imageClicked(jigpaw);
    };

    if (url) {
        return (
            <Card elevation={0} square className={classes.root} onClick={handleClick} style={{backgroundColor: "white"}}>
                <CardActionArea style={{backgroundColor: "white"}}>
                    <RetryImage alt={"jigpaw imageC"} src={url} width={"100%"} height={"100%"}  />
                </CardActionArea>
            </Card>
        );
    }

    return (
        null
    )
}

const JigpawPicker = (props) => {
    const db = getFirestore(app);
    const {user, imageClicked} = props;




     const uppy = useUppy(() => {
        return new Uppy({
            autoProceed: true,
            restrictions: {
                maxFileSize: 10000000,
                allowedFileTypes: [
                    ".jpg",
                    ".jpeg",
                    ".png",
                    ".gif",
                    ".JPG",
                    ".JPEG",
                    ".PNG",
                    ".GIF"
                ]
            }
        })
        .use(FirebaseCloudStorage, {storageRef: uploadRef})
        .use(Compressor, { quality: 1 });;

    });

    uppy.on("upload-started", (file) => {
        console.log("upload-started", file);
        if (user) {
            createJigpaw(user.uid, file.uuid);
        }

    });

    uppy.on("upload-success", (file, snapshot) => {

        console.log("upload-success", file);
        // console.log(snapshot);
        uppy.removeFile(file.id);  // Remove the file from Uppy after successful upload
    });






    const jigpawsQuery = query(
        collection(db, "jigpaws").withConverter(jigpawConverter),
        where("uid", "==", props.user.uid),
        where("deleted", "==", false),
        orderBy("createdAt", "desc"),
        limit(100)
    );
    //todo
    // const [jigpaws, jigpawsLoading, JigpawsError] = useCollectionData(
        const [jigpaws ] = useCollectionData(
        jigpawsQuery,
        {
            snapshotListenOptions: {includeMetadataChanges: true},
        }
    );

    if (jigpaws) {
        return (
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={4} sm={3} md={2} lg={2}>
                        <Square>
                        <Card square elevation={0} variant="outlined" style={{ height: "100%", width: "100%" }} className={"fixDragDrop"}>
        <div style={{ height: "100%", width: "100%", position: "relative" }}>
            <DragDrop
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "white", 
                    padding: 0, 
                    margin: 0, 
                    color: "white",
                }}
                uppy={uppy}
                locale={{
                    strings: {
                        dropHereOr: '%{browse}',
                        browse: '&nbsp;',
                    },
                }}
            />
            <div style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                fontSize: "6rem",
                fontWeight: 100, 
                color: "#85A2AD",
                zIndex: 2,
                pointerEvents: "none"
            }}>+</div>
        </div>
    </Card>
                        </Square>
                    </Grid>
                    {jigpaws.map((jigpaw) =>
                        <Grid item xs={4} sm={3} md={2} lg={2}>
                            <Square>
                                <JigpawSquare key={jigpaw.uuid} jigpaw={jigpaw} user={user}
                                              imageClicked={imageClicked}></JigpawSquare>
                            </Square>
                        </Grid>
                    )}
                </Grid>
            </Container>
        )
    }
    return (null);
};


// https://v4.mui.com/components/dialogs/#draggable-dialog use a draggable dialog maybe for multi picker...
export const JigpawSinglePicker = (props) => {
    const classes = useStyles();
    const {onClose, open} = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md')); //When to go full screen

    const handleClose = () => {
        onClose();
    };

    const handleImageClick = (value) => {
        onClose(value);
    };

    return (
        <Dialog fullWidth={true} maxWidth={"lg"} fullScreen={fullScreen}
                onClose={handleClose} classes={classes.paper} open={open}>
            <DialogTitle className="pickerTitle" id="simple-dialog-title" style={{"background-color":"white"}}>
                <typography variant="h2">Select image</typography>
                <IconButton
                    aria-label="close"
                    className={`${classes.closeButton} jpIcon`}
                    onClick={handleClose}
                    size="large">
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent style={{"padding-bottom": "20px", "padding-left":"0px", "background-color":"white"}}>
                <JigpawPicker imageClicked={handleImageClick} user={props.user}></JigpawPicker>
            </DialogContent>
        </Dialog>
    );
}

JigpawSinglePicker.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};
