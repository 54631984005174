import { initializeApp } from 'firebase/app';
import {
    EmailAuthProvider,
    getAuth,
    linkWithCredential,
    onAuthStateChanged,
    signInAnonymously,
    signInWithEmailAndPassword,
    updateProfile
} from "firebase/auth";
// import {connectAuthEmulator} from "firebase/firebase-auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getStorage, ref } from "firebase/storage";
import {createBasket, createStockImages} from "./models";
import {jigpawAPI} from "./API";


const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    // messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

export const app = initializeApp(firebaseConfig);

export const auth = getAuth();
export const db = getFirestore();



// if (process.env.REACT_APP_USE_AUTH_EMULATOR) {
//     connectAuthEmulator(auth, "http://localhost:9099");
//     console.log("using auth emulator", process.env.REACT_APP_USE_AUTH_EMULATOR);
// }

if (process.env.REACT_APP_USE_FIRESTORE_EMULATOR === 1) {
    connectFirestoreEmulator(db, "http://localhost:8099");
    console.log("using firestore emulator", process.env.REACT_APP_USE_FIRESTORE_EMULATOR);
}

let signingIn = false;
let newUser = false;

onAuthStateChanged(auth, (user) => {
    if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        // ...
        // console.log(user);

        // console.log(process.env);
        if (process.env.REACT_APP_USE_AUTH_EMULATOR === 1) {
            console.log("using emulator....");
        }
        if (newUser && user) {
            newUser = false;
            createStockImages(user.uid);
            createBasket(user.uid);
        }
    } else {
        // User is signed out
        // ...
        if (!signingIn) {
            signingIn = true;
            console.log("user is signed out, sign them in anonymously...")
            signInAnonymously(auth)
                .then(() => {
                    // Signed in..
                    signingIn = false;
                    newUser = true;
                    console.log("signedInAnonymouslyForFirstTime..", auth.currentUser);
                    createStockImages(auth.currentUser.uid);
                })
                .catch((error) => {
                    // const errorCode = error.code;
                    // const errorMessage = error.message;
                    signingIn = false;
                });
        }
    }
});



// export const firebaseAuth = firebase.auth();
// export const firestore = firebase.firestore()
export const defaultBucket = getStorage(app);
export const jigpawBucket = getStorage(app, "gs://" + process.env.REACT_APP_JIGPAW_BUCKET);
export const publicBucket = getStorage(app, "gs://" + process.env.REACT_APP_PUBLIC_BUCKET);
export const anonymousBucket = getStorage(app, "gs://" + process.env.REACT_APP_ANONYMOUS_BUCKET);

export const bucketForUser = (user) => {
    if (user) {
        return (user.isAnonymous) ? anonymousBucket : jigpawBucket;
    }
    return anonymousBucket;
};

export const uploadRef = (uuid, fileExtension) => {
    const auth = getAuth(app);
    const user = auth.currentUser;
    console.log(user);
    if (user) {
        const bucket = bucketForUser(user);
        const path = user.uid + "/" + uuid + "/web." + fileExtension;
        return ref(bucket, path);
    }
};

export const upgradeAnonymousAccount = (user, displayName, email, password) => {
    return jigpawAPI.hand(user, email).then(() => {
        const previousUID = user.uid;
        return linkCreds(user, displayName, email, password, previousUID);
    });
};

const linkCreds = (user, displayName, email, password, previousUID) => {
    const credential = EmailAuthProvider.credential(email, password);
    return linkWithCredential(user, credential)
        .then((usercred) => {
          const newUser = usercred.user;
          console.log("Anonymous upgraded successfully", newUser, newUser.uid, previousUID);
          updateDisplayName(newUser, displayName);
          return jigpawAPI.shake(newUser, previousUID); //if this works I think previousUID and UID will always be the same
        }).catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log("Error upgrading anonymous account", errorMessage, errorCode);
            throw (error)
    });
  }

const updateDisplayName = (user, displayName) => {
  updateProfile(user, {
      displayName: displayName
  }).then(() => {
      console.log("Added display name to user profile.")
  }).catch((error) => {
      console.log("Error adding display name", error);
  });
}

export const signIn = (user, email, password) => {
     return jigpawAPI.hand(user, email).then(() => {
        const previousUID = user.uid;
        return signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const newUser = userCredential.user;
                console.log("successful sign in", userCredential, newUser, previousUID);
                return jigpawAPI.shake(newUser, previousUID); //should be different uid
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
                throw(error)
            });
    });
}