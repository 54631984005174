import React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

import _ from "lodash";
import CountrySelect2 from "../../global/components/CountrySelect2";


const ShippingForm = (props) => {
    const {
        email, setEmail,
        shippingName, setShippingName,
        shippingLine1, setShippingLine1,
        shippingLine2, setShippingLine2,
        shippingCity, setShippingCity,
        shippingState, setShippingState,
        shippingPostalCode, setShippingPostalCode,
        shippingCountry, setShippingCountry,
        shippingPhone, setShippingPhone,
        countries,
        shippingErrors, setShippingErrors,
    } = props;

    return (
        <div>
            <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <TextField
                    id="filled-basic"
                    label="Email"
                    value={email}
                    required
                    error={shippingErrors.email}
                    helperText={shippingErrors.email}
                    onChange={e => {
                        setShippingErrors(_.omit(shippingErrors, "email"));
                        setEmail(e.target.value)
                    }}
                    variant="filled"
                    fullWidth/>
            </Grid>
            </Grid>
            <br/>
            <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <TextField
                    id="filled-basic"
                    label="Name"
                    value={shippingName}
                    required
                    error={shippingErrors.shippingName}
                    helperText={shippingErrors.shippingName}
                    onChange={e => {
                        setShippingErrors(_.omit(shippingErrors, "shippingName"));
                        setShippingName(e.target.value)
                    }}
                    variant="filled"
                    fullWidth/>
            </Grid>
            </Grid>
            <br/>
            <Grid item xs={12}>
                <TextField
                    id="filled-basic"
                    label="Address 1"
                    value={shippingLine1}
                    required
                    error={shippingErrors.shippingLine1}
                    helperText={shippingErrors.shippingLine1}
                    onChange={e => {
                        setShippingErrors(_.omit(shippingErrors, "shippingLine1"));
                        setShippingLine1(e.target.value)
                    }}
                    variant="filled"
                    fullWidth/>
            </Grid>
            <br/>
            <Grid item xs={12}>
                <TextField
                    id="filled-basic"
                    label="Address 2"
                    value={shippingLine2}
                    error={shippingErrors.shippingLine2}
                    helperText={shippingErrors.shippingLine2}
                    onChange={e => {
                        setShippingErrors(_.omit(shippingErrors, "shippingLine2"));
                        setShippingLine2(e.target.value)
                    }}
                    variant="filled"
                    fullWidth/>
            </Grid>
            <br/>
                        <Grid container spacing={2}>

            <Grid item xs={6} md={6}>
                    <TextField
                        id="filled-basic"
                        label="City"
                        value={shippingCity}
                        error={shippingErrors.shippingCity}
                        helperText={shippingErrors.shippingCity}
                        onChange={e => {
                            setShippingErrors(_.omit(shippingErrors, "shippingCity"));
                            setShippingCity(e.target.value)
                        }}
                        variant="filled"
                        fullWidth/>
            </Grid>
                        </Grid>
            <br/>
            <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                    <TextField
                        id="filled-basic"
                        label="Region"
                        value={shippingState}
                        error={shippingErrors.shippingState}
                        helperText={shippingErrors.shippingState}
                        onChange={e => {
                            setShippingErrors(_.omit(shippingErrors, "shippingState"));
                            setShippingState(e.target.value)
                        }}
                        variant="filled"
                        fullWidth/>
                </Grid>
                <Grid item xs={6} md={6}>
                    <TextField
                        id="filled-basic"
                        label="Post / Zip Code"
                        value={shippingPostalCode}
                        error={shippingErrors.shippingPostalCode}
                        helperText={shippingErrors.shippingPostalCode}
                        required
                        onChange={e => {
                            setShippingErrors(_.omit(shippingErrors, "shippingPostalCode"));
                            setShippingPostalCode(e.target.value);
                        }}
                        variant="filled"
                        fullWidth/>
                </Grid>
            </Grid>
            <br/>
            <Grid item xs={12}>
                <TextField
                    id="phone-number"
                    label="Phone Number"
                    value={shippingPhone}
                    error={shippingErrors.shippingPhone}
                    helperText={shippingErrors.shippingPhone}
                    required
                    onChange={e => {
                        setShippingErrors(_.omit(shippingErrors, "shippingPhone"));
                        setShippingPhone(e.target.value);
                    }}
                    variant="filled"
                    fullWidth/>
            </Grid>
            <br/>
            {/*{countries &&*/}
            {/*<Grid item xs={12}>*/}
            {/*    <CountrySelect*/}
            {/*        label="Country"*/}
            {/*        countries={countries}*/}
            {/*        id="shippingCountry"*/}
            {/*        value={shippingCountry}*/}
            {/*        required*/}
            {/*        error={shippingErrors.shippingCountry}*/}
            {/*        helperText={shippingErrors.shippingCountry}*/}
            {/*        onChange={(event, newValue) => {*/}
            {/*            setShippingErrors(_.omit(shippingErrors, "shippingCountry"));*/}
            {/*            setShippingCountry(_.get(newValue, "isoCode"))*/}
            {/*        }}>*/}
            {/*    </CountrySelect>*/}
            {/*</Grid>*/}
            {/*}*/}
            {countries &&
            <Grid item xs={12} style={{textAlign: "left"}}>
                <CountrySelect2
                    width={"100%"}
                    label="Country"
                    countries={countries}
                    id="shippingCountry"
                    value={shippingCountry}
                    required
                    error={shippingErrors.shippingCountry}
                    helperText={shippingErrors.shippingCountry}
                    onChange={(e) => {
                        setShippingErrors(_.omit(shippingErrors, "shippingCountry"));
                        setShippingCountry(e.target.value)
                    }}>
                </CountrySelect2>
            </Grid>
            }
        </div>
    )
}

export default ShippingForm